import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b9ce35c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "input-container"
}
const _hoisted_2 = { class: "text-length-container" }
const _hoisted_3 = ["id", "disabled", "required", "value", "minlength", "maxlength"]
const _hoisted_4 = {
  key: 1,
  class: "input-container"
}
const _hoisted_5 = {
  key: 0,
  class: "input-unit"
}
const _hoisted_6 = ["id", "disabled", "required", "min", "minlength", "max", "maxlength", "value", "type", "step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type === 'text-multiline')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, "# Characters: " + _toDisplayString((_ctx.value as string)?.length), 1),
        _createElementVNode("textarea", {
          class: _normalizeClass(["form-control", [{ invalid: _ctx.invalid }, 'form-control-' + _ctx.size]]),
          id: _ctx.uniqueId,
          disabled: _ctx.disabled,
          required: _ctx.required,
          value: _ctx.value,
          minlength: _ctx.minlength,
          maxlength: _ctx.maxlength,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update$.next(($event.target as HTMLTextAreaElement).value))),
          rows: "5",
          ref: "input"
        }, null, 42, _hoisted_3)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        (_ctx.unit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.unit), 1))
          : _createCommentVNode("", true),
        _createElementVNode("input", {
          class: _normalizeClass(["form-control", [{ invalid: _ctx.invalid }, 'form-control-' + _ctx.size]]),
          id: _ctx.uniqueId,
          disabled: _ctx.disabled,
          required: _ctx.required,
          min: _ctx.min,
          minlength: _ctx.minlength,
          max: _ctx.max,
          maxlength: _ctx.maxlength,
          value: _ctx.value,
          type: _ctx.type,
          step: _ctx.step,
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update$.next(($event.target as HTMLInputElement).value))),
          ref: "input"
        }, null, 42, _hoisted_6)
      ]))
}