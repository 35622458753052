import { Module } from 'vuex';
import { Editor } from '@tiptap/vue-3';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';

import { RootState } from '@/store';
import { Content } from '@/services/contents/contents.types';
import { contentsService } from '@/services/contents/contents.service';

export interface ContentState {
  content: Content;
  contentLoading: boolean;
  editor: Editor;
}

const state: ContentState = {
  content: null,
  contentLoading: false,
  editor: new Editor({
    content: null,
    extensions: [
      Image,
      Link.configure({
        HTMLAttributes: {
          class: 'links-ec'
        }
      }),
      StarterKit.configure({
        heading: {
          HTMLAttributes: {
            class: 'headings-ec'
          }
        }
      }),
      Underline
    ]
  })
};

const options: Module<ContentState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setContent: async ({ state, commit, dispatch }, content: Content | string): Promise<void> => {
      if (typeof content === 'string') await dispatch('getContent', content);
      else commit('content', content);

      state.editor.commands.setContent(state.content?.html);
    },
    getContent: ({ commit, dispatch }, section: string): Promise<void> => {
      commit('contentLoading', true);
      return contentsService
        .get(section)
        .then(content => content && commit('content', content))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('contentLoading', false));
    },
    updateContent: ({ commit, dispatch }, content: Content): Promise<void> => {
      commit('contentLoading', true);
      return contentsService
        .update(content.section, content)
        .then(content => content && commit('content', content))
        .then(() => dispatch('alert/pushSuccess', 'Content successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('contentLoading', false));
    },
    clearContent: async ({ commit, state }): Promise<void> => {
      commit('content', null);
      state.editor.commands.clearContent();
    }
  },
  mutations: {
    content: (state, content) => (state.content = content),
    contentLoading: (state, loading) => (state.contentLoading = loading)
  }
};

export default options;
