import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["spinner-placeholder-container", [_ctx.align]])
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["fa-pulse fa-2x", [_ctx.icon]])
    }, null, 2)
  ], 2))
}