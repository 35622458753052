import { capitalize } from 'lodash';

class DicoLabelsService {
  formatName(user: { first_name?: string; last_name?: string } = {}): string {
    const { first_name, last_name } = user;
    return (
      '' +
      (first_name ? capitalize(first_name) : '') +
      (last_name ? (first_name ? ' ' : '') + last_name.toUpperCase() : '') +
      (!first_name && !last_name ? 'Inconnu' : '')
    );
  }
}

export const dicoLabelsService = new DicoLabelsService();
