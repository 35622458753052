import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", ['btn-' + _ctx.theme, 'btn-' + _ctx.size, { active: _ctx.active }]]),
    disabled: _ctx.disabled,
    type: _ctx.type
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}