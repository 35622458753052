
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

import CustomBtn from '@/components/Misc/CustomBtn/CustomBtn.vue';
import FormCpnt from '@/components/Forms/Form/Form.vue';
import SectionTitle from '@/components/Sections/SectionTitle/SectionTitle.vue';
import SectionHeader from '@/components/Sections/SectionHeader/SectionHeader.vue';
import { Form } from '@/services/forms/form.class';
import { Message } from '@/services/messages/messages.types';
import { catchError, interval, of, switchMap } from 'rxjs';
import { datesService } from '@/services/dates/dates.service';

export default defineComponent({
  name: 'ContactView',
  components: { CustomBtn, FormCpnt, SectionTitle, SectionHeader },
  data() {
    return {
      poller: null
    };
  },
  computed: {
    ...mapState('gallery', ['gallery']),
    ...mapState('message', ['messages']),
    messageForm() {
      return new Form<Message>([
        {
          name: 'message',
          controls: [
            {
              name: 'content',
              label: 'Message',
              type: 'text-multiline',
              value: '',
              required: true
            }
          ]
        }
      ]);
    }
  },
  methods: {
    ...mapActions('message', ['indexMessages', 'createMessage']),
    send() {
      if (this.messageForm.validate()) {
        const message = { ...this.messageForm.value, side: 'gallery' };
        this.createMessage({ galleryId: this.gallery.id, message }).then(() => this.messageForm.reset());
      }
    },
    formatAuthor(message: Message) {
      return message.side === 'gallery' ? this.gallery.name : 'Asia Now';
    },
    formatTimestamp(timestamp: string) {
      return `on ${datesService.format(timestamp)}`;
    }
  },
  watch: {
    messages: {
      immediate: true,
      handler(messages, oldMessages) {
        if (messages?.length > oldMessages?.length) {
          setTimeout(() => this.$refs.list.lastElementChild.scrollIntoView({ behavior: 'smooth' }), 0);
        }
      }
    }
  },
  mounted() {
    this.poller = interval(1000)
      .pipe(
        switchMap(() => this.indexMessages(this.gallery.id)),
        catchError(error => {
          console.log('Poller error', error);
          return of([]);
        })
      )
      .subscribe();
  },
  unmounted() {
    this.poller.unsubscribe();
  }
});
