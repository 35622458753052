
import { defineComponent, PropType } from 'vue';

import { CustomBtnTheme, CustomBtnSize, CustomBtnType } from './CustomBtn.types';

export default defineComponent({
  name: 'CustomBtn',
  props: {
    theme: {
      type: String as PropType<CustomBtnTheme>,
      default: () => 'primary'
    },
    size: {
      type: String as PropType<CustomBtnSize>,
      default: () => 'md'
    },
    type: {
      type: String as PropType<CustomBtnType>,
      default: () => 'button'
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    active: {
      type: Boolean,
      default: () => false
    }
  }
});
