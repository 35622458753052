
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessage from '@/components/Alerts/AlertMessage/AlertMessage.vue';
import FileInput from '@/components/Forms/FileInput/FileInput.vue';
import FormCpnt from '@/components/Forms/Form/Form.vue';
import SectionTitle from '@/components/Sections/SectionTitle/SectionTitle.vue';
import SectionHeader from '@/components/Sections/SectionHeader/SectionHeader.vue';
import { FormControl } from '@/services/forms/form-control.class';
import { datesService } from '@/services/dates/dates.service';
import { Invoice } from '@/services/galleries/galleries.types';

export default defineComponent({
  name: 'PricingView',
  components: { AlertMessage, FileInput, FormCpnt, SectionTitle, SectionHeader },
  computed: {
    ...mapState('content', ['content']),
    ...mapState('gallery', ['gallery']),
    ...mapGetters('auth', ['isGallery']),
    newInvoiceControl() {
      return new FormControl({
        name: 'new-invoice',
        label: 'Upload new invoice',
        type: 'file',
        value: null
      });
    }
  },
  methods: {
    ...mapActions('content', ['setContent', 'clearContent']),
    ...mapActions('gallery', ['addInvoice', 'deleteInvoice']),
    invoiceForm(invoice: Invoice) {
      return new FormControl({
        name: `invoice-${invoice.id}`,
        label: `Invoice uploaded on ${datesService.format(invoice.created_at)}`,
        type: 'file',
        value: invoice.pdf,
        deletable: !this.isGallery
      });
    },
    handleUpload(event: { name: string; file: File }) {
      if (event.name === 'new-invoice' && !!event.file) this.addInvoice(event.file);
    }
  },
  mounted() {
    this.setContent('pricing');
  },
  unmounted() {
    this.clearContent();
  }
});
