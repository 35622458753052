import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b1c9b4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-item" }
const _hoisted_2 = { class: "item-icon" }
const _hoisted_3 = { class: "item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar-item-container", { active: _ctx.active, disabled: _ctx.disabled }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.item.icon)
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.label), 1)
    ])
  ], 2))
}