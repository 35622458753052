
import { defineComponent } from 'vue';

import AlertMessage from '@/components/Alerts/AlertMessage/AlertMessage.vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'AlertFeed',
  components: {
    AlertMessage
  },
  computed: {
    ...mapState('alert', ['alerts'])
  },
  methods: {
    ...mapActions('alert', ['deleteAlert'])
  }
});
