import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal-section-container", [ _ctx.slideClass, _ctx.directionClass, { 'no-bg': _ctx.noBg, 'no-scroll': _ctx.noScroll } ]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["section-scroll-container", { 'p-0': _ctx.noPadding }])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}