import { Module } from 'vuex';
import { pick } from 'lodash';

import { RootState } from '@/store';
import { Work } from '@/services/works/works.types';
import { worksService } from '@/services/works/works.service';
import { Form } from '@/services/forms/form.class';

export interface WorkState {
  work: Work;
  workLoading: boolean;
}

const state: WorkState = {
  work: null,
  workLoading: false
};

const options: Module<WorkState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setWork: ({ commit, dispatch }, work: Work | string | number): Promise<void> => {
      if (typeof work === 'number' || typeof work === 'string') return dispatch('getWork', work);
      else commit('work', work);
    },
    getWork: ({ commit, dispatch }, id: number | string): Promise<void> => {
      commit('workLoading', true);
      return worksService
        .get(+id)
        .then(work => work && commit('work', work))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('workLoading', false));
    },
    createWork: ({ commit, dispatch }, work: Partial<Work>): Promise<void> => {
      commit('work', null);
      commit('workLoading', true);
      return worksService
        .create(work)
        .then(work => work && commit('work', work))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('workLoading', false));
    },
    updateWork: ({ commit, dispatch }, work: Work): Promise<void> => {
      commit('workLoading', true);
      return worksService
        .update(work.id, work)
        .then(() => dispatch('alert/pushSuccess', 'Art work successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('workLoading', false));
    },
    updateWorkFiles: (
      { state, commit, dispatch },
      { files, work }: { files: Record<string, File>; work: Partial<Work> }
    ): Promise<void> => {
      commit('workLoading', true);
      return worksService
        .updateFiles(state.work.id, files)
        .then(wrk => wrk && commit('work', { ...wrk, ...work, ...pick(wrk, Object.keys(files)) }))
        .then(() => dispatch('alert/pushSuccess', 'Art work successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('workLoading', false));
    },
    deleteWorkPicture: ({ state, commit, dispatch }): Promise<void> => {
      commit('workLoading', true);
      return worksService
        .deletePicture(state.work?.id)
        .then(work => work && commit('work', work))
        .then(() => dispatch('alert/pushSuccess', 'Work successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('workLoading', false));
    },
    deleteWork: (
      { commit, dispatch },
      { workId, showAlert = true }: { workId: number; showAlert?: boolean }
    ): Promise<void> => {
      commit('workLoading', true);
      return worksService
        .delete(workId)
        .then(() => commit('work', null))
        .then(() => showAlert && dispatch('alert/pushSuccess', 'Art work successfully deleted!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('workLoading', false));
    }
  },
  mutations: {
    work: (state, work) => (state.work = work),
    workLoading: (state, loading) => (state.workLoading = loading)
  },
  getters: {
    workForm: state => {
      if (!state.work) return;

      return new Form<Work>([
        {
          name: 'work',
          style: [
            'display: grid',
            'grid-template-columns: repeat(2, 1fr)',
            'grid-template-rows: repeat(5, 1fr)',
            'column-gap: 1rem'
          ].join(';'),
          controls: [
            {
              name: 'picture',
              label: 'Visual',
              type: 'visual',
              value: state.work.picture,
              style: ['grid-row: 1 / 4', 'grid-column: 1 / 3'].join(';'),
              required: true
            },
            {
              name: 'name',
              label: 'Work name',
              type: 'text',
              value: state.work.name,
              required: true
            },
            {
              name: 'year',
              label: 'Year',
              type: 'text',
              value: state.work.year,
              required: true
            },
            {
              name: 'technique',
              label: 'Technique',
              type: 'text',
              value: state.work.technique,
              required: true
            },
            {
              name: 'dimensions',
              label: 'Dimensions',
              type: 'text',
              value: state.work.dimensions,
              class: 'mb-0',
              required: true
            }
          ]
        }
      ]);
    }
  }
};

export default options;
