import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_group_cpnt = _resolveComponent("form-group-cpnt")!

  return (_ctx.form)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        class: "form",
        onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submit', $event)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.groups, (group) => {
          return (_openBlock(), _createBlock(_component_form_group_cpnt, {
            key: group.name,
            group: group,
            size: _ctx.size,
            disabled: _ctx.disabled,
            inline: _ctx.inline,
            onUpload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('upload', $event))),
            onPurge: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('purge', $event)))
          }, null, 8, ["group", "size", "disabled", "inline"]))
        }), 128))
      ], 32))
    : _createCommentVNode("", true)
}