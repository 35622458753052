import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import store from './store';
import AboutView from './views/AboutView.vue';
import AdminView from './views/AdminView.vue';
import OverviewView from './views/OverviewView.vue';
import GalleryView from './views/GalleryView.vue';
import BadgeView from './views/BadgeView.vue';
import BoothView from './views/BoothView.vue';
import BroadcastView from './views/BroadcastView.vue';
import ContactView from './views/ContactView.vue';
import ContentView from './views/ContentView.vue';
import GalleriesView from './views/GalleriesView.vue';
import InsuranceView from './views/InsuranceView.vue';
import ProjectView from './views/ProjectView.vue';
import PricingView from './views/PricingView.vue';
import ServiceView from './views/ServiceView.vue';
import ShippingView from './views/ShippingView.vue';
import HomeView from './views/HomeView.vue';
import LoginView from './views/LoginView.vue';
import LogoutView from './views/LogoutView.vue';
import CustomSidebar from './components/Sidebar/CustomSidebar/CustomSidebar.vue';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'login',
    path: '/login',
    component: LoginView
  },
  {
    name: 'about',
    path: '/about',
    component: AboutView
  },
  {
    name: 'home',
    path: '/',
    component: HomeView,
    redirect: { name: 'admin' },
    children: [
      {
        name: 'admin',
        path: '/admin',
        components: {
          sidebar: CustomSidebar,
          default: AdminView
        },
        props: { default: true, sidebar: { route: 'admin' } },
        children: [
          {
            name: 'admin-overview',
            path: 'overview',
            component: OverviewView
          },
          {
            name: 'admin-galleries',
            path: 'galleries',
            component: GalleriesView,
            children: [
              {
                name: 'admin-gallery',
                path: ':id',
                components: {
                  sidebar: CustomSidebar as any,
                  default: GalleryView as any
                },
                props: {
                  default: true,
                  sidebar: { route: 'admin-gallery', showLogo: false, showGallery: true, showUser: false }
                },
                children: [
                  {
                    name: 'admin-gallery-project',
                    path: 'project',
                    component: ProjectView
                  },
                  {
                    name: 'admin-gallery-pricing',
                    path: 'pricing',
                    component: PricingView
                  },
                  {
                    name: 'admin-gallery-booth',
                    path: 'booth',
                    component: BoothView
                  },
                  {
                    name: 'admin-gallery-badge',
                    path: 'badge',
                    component: BadgeView
                  },
                  {
                    name: 'admin-gallery-shipping',
                    path: 'shipping',
                    component: ShippingView
                  },
                  {
                    name: 'admin-gallery-service',
                    path: 'service',
                    component: ServiceView
                  },
                  {
                    name: 'admin-gallery-insurance',
                    path: 'insurance',
                    component: InsuranceView
                  },
                  {
                    name: 'admin-gallery-broadcast',
                    path: 'broadcast',
                    component: BroadcastView
                  },
                  {
                    name: 'admin-gallery-contact',
                    path: 'contact',
                    component: ContactView
                  }
                ]
              }
            ]
          },
          {
            name: 'admin-content',
            path: 'content',
            component: ContentView
          }
        ]
      },
      {
        name: 'gallery',
        path: '/gallery/:id',
        components: {
          sidebar: CustomSidebar,
          default: GalleryView
        },
        props: { default: true, sidebar: { route: 'gallery' } },
        children: [
          {
            name: 'gallery-overview',
            path: 'overview',
            component: OverviewView
          },
          {
            name: 'gallery-project',
            path: 'project',
            component: ProjectView
          },
          {
            name: 'gallery-pricing',
            path: 'pricing',
            component: PricingView
          },
          {
            name: 'gallery-booth',
            path: 'booth',
            component: BoothView
          },
          {
            name: 'gallery-badge',
            path: 'badge',
            component: BadgeView
          },
          {
            name: 'gallery-shipping',
            path: 'shipping',
            component: ShippingView
          },
          {
            name: 'gallery-service',
            path: 'service',
            component: ServiceView
          },
          {
            name: 'gallery-insurance',
            path: 'insurance',
            component: InsuranceView
          },
          {
            name: 'gallery-broadcast',
            path: 'broadcast',
            component: BroadcastView
          },
          {
            name: 'gallery-contact',
            path: 'contact',
            component: ContactView
          }
        ]
      },
      {
        name: 'logout',
        path: '/logout',
        component: LogoutView
      }
    ],
    beforeEnter(to, from, next) {
      if (store.getters['auth/isAuthenticated']) next();
      else next('login');
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/login'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
