import moment from 'moment';

class DatesService {
  format(dateString: string, format = 'Do MMMM YYYY [at] HH:mm'): string {
    try {
      return moment(dateString).format(format);
    } catch {
      return '[Invalide]';
    }
  }

  parse(dateString: string, format = 'Do MMMM YYYY HH:mm'): Date {
    return moment(dateString, format).toDate();
  }
}

export const datesService = new DatesService();
