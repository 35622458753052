import axios from '@/core/axios';
import { ListResult } from '@/core/axios.types';

import { Gallery, GalleriesListOptions } from './galleries.types';

export class GalleriesService {
  list(opts: GalleriesListOptions = {}): Promise<ListResult<Gallery>> {
    const { filters, pagination } = opts;

    return axios
      .get<ListResult<Gallery>>(`/galleries`, {
        params: { ...filters, ...pagination }
      })
      .then(resp => resp?.data);
  }

  get(galleryId: number): Promise<Gallery> {
    return axios.get<Gallery>(`/galleries/${galleryId}`).then(resp => resp?.data);
  }

  update(galleryId: number | string, gallery: Partial<Gallery>): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in update');
    return axios.put<Gallery>(`galleries/${galleryId}`, { gallery }).then(resp => resp?.data);
  }

  updateFiles(galleryId: number | string, files: Record<string, File>): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in updateFiles');

    const formData = Object.entries(files).reduce((prev, curr) => {
      prev.append(`gallery[${curr[0]}]`, curr[1]);
      return prev;
    }, new FormData());

    return axios
      .put<Gallery>(`galleries/${galleryId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(resp => resp?.data);
  }

  addInvoice(galleryId: number | string, file: File): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in addInvoice');

    const formData = new FormData();
    formData.append('gallery[invoice]', file);

    return axios
      .post<Gallery>(`galleries/${galleryId}/invoices`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  deleteInvoice(galleryId: number | string, invoiceId: number | string): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in deletePicture');

    return axios.delete<Gallery>(`galleries/${galleryId}/invoices/${invoiceId}`).then(resp => resp?.data);
  }

  addInsurance(galleryId: number | string, file: File): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in addInsurance');

    const formData = new FormData();
    formData.append('gallery[insurance]', file);

    return axios
      .post<Gallery>(`galleries/${galleryId}/insurances`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  deleteInsurance(galleryId: number | string, insuranceId: number | string): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in deletePicture');

    return axios.delete<Gallery>(`galleries/${galleryId}/insurances/${insuranceId}`).then(resp => resp?.data);
  }

  addFormBadge(galleryId: number | string, file: File): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in addFormBadge');

    const formData = new FormData();
    formData.append('gallery[form_badge]', file);

    return axios
      .post<Gallery>(`galleries/${galleryId}/form_badges`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  deleteFormBadge(galleryId: number | string, formId: number | string): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in deleteFormBadge');

    return axios.delete<Gallery>(`galleries/${galleryId}/form_badges/${formId}`).then(resp => resp?.data);
  }

  addFormShipping(galleryId: number | string, file: File): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in addFormShipping');

    const formData = new FormData();
    formData.append('gallery[form_shipping]', file);

    return axios
      .post<Gallery>(`galleries/${galleryId}/form_shippings`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  deleteFormShipping(galleryId: number | string, formId: number | string): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in deleteFormShipping');

    return axios.delete<Gallery>(`galleries/${galleryId}/form_shippings/${formId}`).then(resp => resp?.data);
  }

  deleteBoothPlan(galleryId: number | string): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in deleteBoothPlan');

    return axios.delete<Gallery>(`galleries/${galleryId}/delete-booth-plan`).then(resp => resp?.data);
  }

  deleteBoothOrderForm(galleryId: number | string): Promise<Gallery> {
    if (!galleryId) throw new Error('Missing gallery id in deleteBoothOrderForm');

    return axios.delete<Gallery>(`galleries/${galleryId}/delete-booth-order-form`).then(resp => resp?.data);
  }
}

export const galleriesService = new GalleriesService();
