
import { defineComponent, PropType } from 'vue';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControlSize } from '@/services/forms/form-control.types';

export default defineComponent({
  name: 'SearchBar',
  emits: ['search'],
  props: {
    placeholder: {
      type: String,
      default: 'Recherche...'
    },
    pending: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<FormControlSize>,
      default: 'md'
    }
  },
  data() {
    return {
      search$: new Subject(),
      searchSubscription: null
    };
  },
  mounted() {
    this.searchSubscription = this.search$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((search: string) => this.$emit('search', search));
  },
  unmounted() {
    this.searchSubscription.unsubscribe();
  }
});
