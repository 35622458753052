import { createStore } from 'vuex';

import alert, { AlertState } from './store/alert.store';
import artist, { ArtistState } from './store/artist.store';
import auth, { AuthState } from './store/auth.store';
import content, { ContentState } from './store/content.store';
import gallery, { GallerieState } from './store/gallery.store';
import message, { MessageState } from './store/message.store';
import modal, { ModalState } from './store/modal.store';
import work, { WorkState } from './store/work.store';

export interface RootState {
  alert: AlertState;
  artist: ArtistState;
  auth: AuthState;
  content: ContentState;
  gallery: GallerieState;
  message: MessageState;
  modal: ModalState;
  work: WorkState;
}

export default createStore({
  modules: {
    alert,
    artist,
    auth,
    content,
    gallery,
    message,
    modal,
    work
  },
  strict: process.env.NODE_ENV !== 'production'
});
