
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessage from '@/components/Alerts/AlertMessage/AlertMessage.vue';
import CustomBtn from '@/components/Misc/CustomBtn/CustomBtn.vue';
import FileInput from '@/components/Forms/FileInput/FileInput.vue';
import SectionTitle from '@/components/Sections/SectionTitle/SectionTitle.vue';
import SectionHeader from '@/components/Sections/SectionHeader/SectionHeader.vue';
import { FormControl } from '@/services/forms/form-control.class';
import { datesService } from '@/services/dates/dates.service';

export default defineComponent({
  name: 'BoothView',
  components: { AlertMessage, CustomBtn, FileInput, SectionTitle, SectionHeader },
  computed: {
    ...mapState('content', ['content']),
    ...mapState('gallery', ['gallery']),
    ...mapGetters('auth', ['isGallery']),
    boothOrderFormControl() {
      return new FormControl({
        name: 'booth_order_form',
        label: this.gallery?.booth_order_form
          ? `Form uploaded on ${datesService.format(this.gallery.booth_order_form.created_at)}`
          : 'Upload the order form',
        type: 'file',
        value: this.gallery?.booth_order_form
      });
    },
    boothPlanControl() {
      return new FormControl({
        name: 'booth_plan',
        label: this.gallery?.booth_plan ? 'Your booth floorplan' : 'Upload booth floorplan',
        type: 'file',
        deletable: !this.isGallery,
        value: this.gallery?.booth_plan
      });
    }
  },
  methods: {
    ...mapActions('content', ['setContent', 'clearContent']),
    ...mapActions('gallery', ['updateGalleryFile', 'deleteBoothPlan', 'deleteBoothOrderForm']),
    handleDownload() {
      window.open(
        process.env.VUE_APP_PUBLIC_BUCKET_URL +
          '/2024%20EDITION%20-%20FORMS/1_Technical%20Booth%20Plan%20Drawing%20.pdf',
        '_blank'
      );
    },
    handleUpload(event: { name: string; file: File }) {
      this.updateGalleryFile({ [event.name]: event.file });
    }
  },
  mounted() {
    this.setContent('booth');
  },
  unmounted() {
    this.clearContent();
  }
});
