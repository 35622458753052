
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import { ConfirmationModalData } from './ConfirmationModal.types';
import ModalLayout from '@/components/Modals/ModalLayout/ModalLayout.vue';
import ModalSection from '@/components/Modals/ModalSection/ModalSection.vue';

export default defineComponent({
  name: 'ConfirmationModal',
  components: { ModalLayout, ModalSection },
  computed: {
    ...mapState('modal', ['modal']),
    message() {
      return this.data?.message || 'Êtes-vous sûr(e) ?';
    }
  },
  data() {
    return {
      data: null as ConfirmationModalData
    };
  },
  mounted() {
    this.data = this.modal.data;
  }
});
