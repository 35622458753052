
import { defineComponent, PropType } from 'vue';
import { debounceTime } from 'rxjs/operators';

import FormGroupCpnt from '@/components/Forms/FormGroup/FormGroup.vue';
import { Form } from '@/services/forms/form.class';
import { FormControlSize } from '@/services/forms/form-control.types';

export default defineComponent({
  name: 'FormCpnt',
  components: { FormGroupCpnt },
  emits: ['update', 'upload', 'purge', 'submit'],
  props: {
    form: {
      type: Object as PropType<Form<unknown>>,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<FormControlSize>,
      default: 'md'
    }
  },
  data() {
    return {
      formValueValueSub: null
    };
  },
  watch: {
    form: {
      immediate: true,
      handler() {
        this.formValueValueSub = this.form.change$.pipe(debounceTime(600)).subscribe(() => {
          this.$emit('update', this.form.value);
        });
      }
    }
  },
  unmounted() {
    this.formValueValueSub.unsubscribe();
  }
});
