
import { defineComponent } from 'vue';
import { EditorContent } from '@tiptap/vue-3';
import { mapActions, mapState } from 'vuex';

import AlertMessage from '@/components/Alerts/AlertMessage/AlertMessage.vue';
import ArtistCard from '@/components/Artists/ArtistCard/ArtistCard.vue';
import ContactCard from '@/components/Contacts/ContactCard/ContactCard.vue';
import CustomBtn from '@/components/Misc/CustomBtn/CustomBtn.vue';
import EditorMenu from '@/components/Editor/EditorMenu/EditorMenu.vue';
import FormCpnt from '@/components/Forms/Form/Form.vue';
import SectionTitle from '@/components/Sections/SectionTitle/SectionTitle.vue';
import SectionHeader from '@/components/Sections/SectionHeader/SectionHeader.vue';

export default defineComponent({
  name: 'ContentView',
  components: {
    AlertMessage,
    ArtistCard,
    ContactCard,
    CustomBtn,
    EditorContent,
    EditorMenu,
    FormCpnt,
    SectionTitle,
    SectionHeader
  },
  data() {
    return {
      activeTab: null
    };
  },
  computed: {
    ...mapState('content', ['content', 'editor'])
  },
  methods: {
    ...mapActions('content', ['setContent', 'updateContent']),
    setActiveTab(tab: string) {
      this.setContent(tab).then(() => {
        this.activeTab = tab;
      });
    },
    saveContent(html: string) {
      this.updateContent({ ...this.content, html });
    }
  },
  mounted() {
    this.setActiveTab('overview');
  }
});
