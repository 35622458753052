import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c38e0018"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "alert-feed-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_message = _resolveComponent("alert-message")!

  return (_ctx.alerts && _ctx.alerts.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (alert) => {
          return (_openBlock(), _createBlock(_component_alert_message, {
            class: "alert",
            key: alert.id,
            content: alert.text,
            theme: alert.type,
            icon: alert.icon,
            duration: alert.duration,
            action: alert.action,
            dismissable: !alert.action,
            onClose: ($event: any) => (_ctx.deleteAlert(alert.id))
          }, null, 8, ["content", "theme", "icon", "duration", "action", "dismissable", "onClose"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}