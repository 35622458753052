
import { defineComponent, PropType } from 'vue';

import { SidebarItem } from './CustomSidebarItem.types';

export default defineComponent({
  name: 'CustomSidebarItem',
  components: {},
  props: {
    item: {
      type: Object as PropType<SidebarItem>,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
});
