
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

import EditWorkModal from '@/components/Works/EditWorkModal/EditWorkModal.vue';
import ConfirmationModal from '@/components/Modals/ConfirmationModal/ConfirmationModal.vue';

export default defineComponent({
  name: 'ModalHolder',
  components: { EditWorkModal, ConfirmationModal },
  computed: {
    ...mapState('modal', ['modal'])
  },
  methods: {
    ...mapActions('modal', ['hideModal']),
    hide() {
      if (this.modal.dismissable) this.hideModal();
    }
  }
});
