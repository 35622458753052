import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51f896c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { class: "app-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_alert_feed = _resolveComponent("alert-feed")!
  const _component_modal_holder = _resolveComponent("modal-holder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_alert_feed),
    _createVNode(_component_modal_holder)
  ]))
}