import { Module } from 'vuex';

import { RootState } from '@/store';
import { Artist } from '@/services/artists/artists.types';
import { artistsService } from '@/services/artists/artists.service';

export interface ArtistState {
  artist: Artist;
  artistLoading: boolean;
}

const state: ArtistState = {
  artist: null,
  artistLoading: false
};

const options: Module<ArtistState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setArtist: ({ commit, dispatch }, artist: Artist | string | number): Promise<void> => {
      if (typeof artist === 'number' || typeof artist === 'string') return dispatch('getArtist', artist);
      else commit('artist', artist);
    },
    getArtist: ({ commit, dispatch }, id: number | string): Promise<void> => {
      commit('artistLoading', true);
      return artistsService
        .get(+id)
        .then(artist => artist && commit('artist', artist))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('artistLoading', false));
    },
    newArtist: ({ commit, dispatch }): Promise<void> => {
      commit('artist', null);
      commit('artistLoading', true);
      return artistsService
        .new()
        .then(artist => artist && commit('artist', artist))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('artistLoading', false));
    }
  },
  mutations: {
    artist: (state, artist) => (state.artist = artist),
    artistLoading: (state, loading) => (state.artistLoading = loading)
  }
};

export default options;
