import { Subject } from 'rxjs';
import { set } from 'lodash';
import moment from 'moment';

import { Form } from './form.class';
import { FormControl } from './form-control.class';
import { FormControlSize } from './form-control.types';
import { FormGroupRaw } from './form-group.types';

export class FormGroup<T> {
  name: string;
  parent: Form<T>;
  controls: FormControl<T>[];
  /**
   * Wether the group is only presentational or if it reflects form's value.
   * If flat = false, formValue = { group: { control1, control2, etc. } }
   * If flat = true, formValue = { control1, control2, etc. }
   */
  flat: boolean;
  label: string;
  size: FormControlSize;
  class: string;
  style: string;
  valid: boolean;
  change$: Subject<void>; // Will be triggered every time the value of a control changes

  get value(): Partial<T> {
    return this.controls
      .filter(control => control.type !== 'file')
      .reduce((value, control) => {
        if (control.type === 'datetime-local' && typeof control.value === 'string') {
          set(value, control.name, moment(control.value).toISOString());
        } else set(value, control.name, control.value);
        return value;
      }, {} as any);
  }

  constructor(group: FormGroupRaw<T>, form?: Form<T>) {
    this.name = group.name;
    this.flat = group.flat === undefined ? true : group.flat;
    this.label = group.label;
    this.valid = false;
    this.parent = form;
    this.size = group.size;
    this.class = group.class;
    this.style = group.style;
    this.change$ = form.change$;
    this.controls = group.controls.map(control => new FormControl(control, this));
  }

  has(path: string): boolean {
    return !!this.controls.find(fc => fc.name === path);
  }

  get(path: string): FormControl<T> {
    const formControl = this.controls.find(fc => fc.name === path);
    if (!formControl) throw new Error(`Cannot find form control ${path}`);
    return formControl;
  }

  validate(): boolean {
    if (!this.controls) return (this.valid = false);
    else if (this.controls.length === 0) return (this.valid = true);
    else return (this.valid = this.controls.reduce((isValid, control) => isValid && control.validate(), true));
  }

  // After form initialization, we execute all afterChange functions a first time (synchronously)
  // (Useful to load asynchronous options for example (cf. Purchase query spare options))
  initAfterChange() {
    this.controls.reduce(
      (promise, control) => promise.then(() => control.afterChange && control.afterChange(control.value, this, false)),
      Promise.resolve()
    );
  }
}
