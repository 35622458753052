import axios from '@/core/axios';
import { ListResult } from '@/core/axios.types';
import { omit } from 'lodash';

import { Artist, ArtistsListOptions } from './artists.types';

export class ArtistsService {
  list(opts: ArtistsListOptions = {}): Promise<ListResult<Artist>> {
    const { filters, pagination } = opts;

    return axios
      .get<ListResult<Artist>>(`/artists`, {
        params: { ...filters, ...pagination }
      })
      .then(resp => resp?.data);
  }

  get(artistId: number): Promise<Artist> {
    return axios.get<Artist>(`/artists/${artistId}`).then(resp => resp?.data);
  }

  new(): Promise<Artist> {
    return axios.get<Artist>(`/artists/new`).then(resp => resp?.data);
  }

  create(artist: Artist & { project_id: number }): Promise<Artist> {
    return axios.post<Artist>(`/artists`, { artist }).then(resp => resp?.data);
  }

  update(artistId: number | string, obj: Partial<Artist>): Promise<Artist> {
    if (!artistId) throw new Error('Missing artist id in update');

    /* Filtering out biography attachment */
    const artist = omit(obj, 'biography');

    return axios.put<Artist>(`artists/${artistId}`, { artist }).then(resp => resp?.data);
  }

  delete(artistId: number | string): Promise<void> {
    if (!artistId) throw new Error('Missing artist id in delete');
    return axios.delete<void>(`artists/${artistId}`).then(resp => resp?.data);
  }

  updateFiles(artistId: number | string, files: Record<string, File>): Promise<Artist> {
    if (!artistId) throw new Error('Missing artist id in updateFiles');

    const formData = Object.entries(files).reduce((prev, curr) => {
      prev.append(`artist[${curr[0]}]`, curr[1]);
      return prev;
    }, new FormData());

    return axios
      .put<Artist>(`artists/${artistId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(resp => resp?.data);
  }

  deleteBiography(artistId: number | string): Promise<Artist> {
    if (!artistId) throw new Error('Missing artist id in deleteBiography');

    return axios.delete<Artist>(`artists/${artistId}/delete-biography`).then(resp => resp?.data);
  }
}

export const artistsService = new ArtistsService();
