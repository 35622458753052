
import { defineComponent, PropType } from 'vue';

import { Work } from '@/services/works/works.types';
import FormCpnt from '@/components/Forms/Form/Form.vue';
import { Artist } from '@/services/artists/artists.types';

export default defineComponent({
  name: 'WorkCard',
  components: { FormCpnt },
  emits: ['edit', 'delete'],
  props: {
    artist: {
      type: Object as PropType<Artist>,
      default: () => null
    },
    work: {
      type: Object as PropType<Work>,
      default: () => null
    }
  }
});
