import { Module } from 'vuex';

import { RootState } from '@/store';
import { authService } from '@/services/auth/auth.service';
import { AuthenticationError, Credentials } from '@/services/auth/auth.types';
import { User } from '@/services/users/users.types';
import { dicoLabelsService } from '@/services/dico-labels/dico-labels.service';

export interface AuthState {
  token: string;
  signingIn: boolean;
  authenticatedUser: User;
  authenticatedUserLoading: boolean;
}

const state: AuthState = {
  token: sessionStorage.getItem('auth-token'),
  signingIn: false,
  authenticatedUser: null,
  authenticatedUserLoading: false
};

const options: Module<AuthState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    getSession: ({ commit, dispatch }): Promise<void> => {
      commit('signingIn', false);

      const token = authService.getSession();
      if (!token) return;
      commit('token', token);
      return dispatch('getAuthenticatedUser');
    },
    getAuthenticatedUser: ({ commit, dispatch }): Promise<void> => {
      commit('authenticatedUserLoading', true);

      return authService
        .getAuthenticatedUser()
        .then(user => user && commit('authenticatedUser', user))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('authenticatedUserLoading', false));
    },
    signIn: ({ state, commit, dispatch }, payload: Credentials): Promise<void> => {
      commit('signingIn', true);
      commit('token', null);

      /* If there is already a token, no need to send the request */
      if (state.token) return;

      return authService
        .signIn(payload)
        .then(token => {
          if (!token) return;
          commit('token', token);
          return dispatch('getAuthenticatedUser');
        })
        .catch(error => {
          if (error instanceof AuthenticationError) throw error;
          else dispatch('alert/pushError', { error }, { root: true });
        })
        .finally(() => commit('signingIn', false));
    },
    signOut: ({ commit }): void => {
      commit('signingIn', false);
      commit('token', null);
      commit('authenticatedUser', null);

      authService.signOut();
    }
  },
  mutations: {
    token: (state, token) => (state.token = token),
    signingIn: (state, signingIn) => (state.signingIn = signingIn),
    authenticatedUser: (state, user) => (state.authenticatedUser = user),
    authenticatedUserLoading: (state, loading) => (state.authenticatedUserLoading = loading)
  },
  getters: {
    isAuthenticated: state => !!state.authenticatedUser,
    authenticatedUserName: state => state.authenticatedUser && dicoLabelsService.formatName(state.authenticatedUser),
    authenticatedGalleryId: (state, getters) => getters.isGallery && state.authenticatedUser?.gallery?.id,
    authenticatedUserRole: (state, getters) => {
      if (!state.authenticatedUser) return;
      if (getters.isAdmin) return 'Admin';
      else if (getters.isAnow) return 'Asia Now';
      else if (getters.isGallery) {
        return state.authenticatedUser?.gallery?.name;
      } else return 'Inconnu';
    },
    isAdmin: state => state.authenticatedUser && !!state.authenticatedUser.roles.find(r => r.name === 'admin'),
    isAcom: state => state.authenticatedUser && !!state.authenticatedUser.roles.find(r => r.name === 'acom'),
    isAprod: state => state.authenticatedUser && !!state.authenticatedUser.roles.find(r => r.name === 'aprod'),
    isArelation: state => state.authenticatedUser && !!state.authenticatedUser.roles.find(r => r.name === 'arelation'),
    isAnow: (state, getters) => getters.isAcom || getters.isAprod || getters.isArelation,
    isGallery: state => state.authenticatedUser && !!state.authenticatedUser.roles.find(r => r.name === 'gallery'),
    allowedSections: (state, getters) => {
      if (getters.isAdmin)
        return [
          'admin-overview',
          'admin-galleries',
          'admin-content',
          'admin-gallery-project',
          'admin-gallery-pricing',
          'admin-gallery-booth',
          'admin-gallery-badge',
          'admin-gallery-shipping',
          'admin-gallery-service',
          'admin-gallery-insurance',
          'admin-gallery-broadcast',
          'admin-gallery-contact'
        ];
      else if (getters.isAcom)
        return ['admin-overview', 'admin-galleries', 'admin-gallery-project', 'admin-gallery-contact'];
      else if (getters.isAprod)
        return [
          'admin-overview',
          'admin-galleries',
          'admin-gallery-booth',
          'admin-gallery-badge',
          'admin-gallery-shipping',
          'admin-gallery-insurance',
          'admin-gallery-contact'
        ];
      else if (getters.isArelation)
        return [
          'admin-overview',
          'admin-galleries',
          'admin-gallery-project',
          'admin-gallery-pricing',
          'admin-gallery-booth',
          'admin-gallery-badge',
          'admin-gallery-shipping',
          'admin-gallery-service',
          'admin-gallery-insurance',
          'admin-gallery-broadcast',
          'admin-gallery-contact'
        ];
      else if (getters.isGallery)
        return [
          'gallery-overview',
          'gallery-project',
          'gallery-pricing',
          'gallery-booth',
          'gallery-badge',
          'gallery-shipping',
          'gallery-service',
          'gallery-insurance',
          'gallery-broadcast',
          'gallery-contact'
        ];
    }
  }
};

export default options;
