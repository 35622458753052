import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "service-container pb-3" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_title = _resolveComponent("section-title")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_section_title, {
      icon: "fa-solid fa-user-check",
      text: "Service"
    }),
    _createElementVNode("div", {
      class: "page-content my-5",
      innerHTML: _ctx.content?.html
    }, null, 8, _hoisted_2)
  ]))
}