import axios from '@/core/axios';
import { omit } from 'lodash';

import { Work } from './works.types';

export class WorksService {
  get(workId: number): Promise<Work> {
    return axios.get<Work>(`/works/${workId}`).then(resp => resp?.data);
  }

  create(work: Partial<Work>): Promise<Work> {
    return axios.post<Work>(`/works`, { work }).then(resp => resp?.data);
  }

  update(workId: number | string, obj: Partial<Work>): Promise<Work> {
    if (!workId) throw new Error('Missing work id in update');

    /* Filtering out picture attachment */
    const work = omit(obj, 'picture');

    return axios.put<Work>(`works/${workId}`, { work }).then(resp => resp?.data);
  }

  delete(workId: number | string): Promise<void> {
    if (!workId) throw new Error('Missing work id in delete');
    return axios.delete<void>(`works/${workId}`).then(resp => resp?.data);
  }

  updateFiles(workId: number | string, files: Record<string, File>): Promise<Work> {
    if (!workId) throw new Error('Missing work id in updateFiles');

    const formData = Object.entries(files).reduce((prev, curr) => {
      prev.append(`work[${curr[0]}]`, curr[1]);
      return prev;
    }, new FormData());

    return axios
      .put<Work>(`works/${workId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(resp => resp?.data);
  }

  deletePicture(workId: number | string): Promise<Work> {
    if (!workId) throw new Error('Missing work id in deletePicture');

    return axios.delete<Work>(`works/${workId}/delete-picture`).then(resp => resp?.data);
  }
}

export const worksService = new WorksService();
