
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import PagingControls from '@/components/Misc/PagingControls/PagingControls.vue';
import SearchBar from '@/components/Misc/SearchBar/SearchBar.vue';
import SectionTitle from '@/components/Sections/SectionTitle/SectionTitle.vue';
import SectionHeader from '@/components/Sections/SectionHeader/SectionHeader.vue';
import { Gallery } from '@/services/galleries/galleries.types';

export default defineComponent({
  name: 'GalleriesView',
  components: { PagingControls, SearchBar, SectionTitle, SectionHeader },
  computed: {
    ...mapState('gallery', ['galleries', 'pagination']),
    sortedGalleries() {
      const reverse = this.sortDirection === 'desc' ? -1 : 1;
      return this.galleriesChecklists()?.sort((a, b) => (a[this.sortBy] - b[this.sortBy]) * reverse);
    }
  },
  data() {
    return {
      columns: [
        { key: 'name', label: '' },
        { key: 'has_curatorial_text', label: 'Curatorial Text' },
        { key: 'has_key_visual', label: 'Key Visual' },
        { key: 'has_invoice', label: 'Invoice uploaded' },
        { key: 'has_floorplan', label: 'Floorplan uploaded' },
        { key: 'has_booth_order_form', label: 'Booth order form uploaded' },
        { key: 'has_badge_application', label: 'Badge Application' },
        { key: 'has_vehicle_access_application', label: 'Vehicle Access Application' },
        { key: 'has_insurance', label: 'Signed Insurance' }
      ],
      sortBy: 'has_curatorial_text',
      sortDirection: 'asc'
    };
  },
  methods: {
    ...mapActions('gallery', ['listGalleries', 'searchBy', 'setPage']),
    ...mapGetters('gallery', ['galleriesChecklists']),
    openGallery(gallery: Gallery) {
      this.$router.push({ name: 'admin-gallery', params: { id: gallery.id } });
    },
    sortTable(sortKey) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      this.sortBy = sortKey;
    }
  },
  mounted() {
    this.listGalleries();
  }
});
