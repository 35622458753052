
import { defineComponent, PropType } from 'vue';
import { uniqueId } from 'lodash';

import { FormControl } from '@/services/forms/form-control.class';

export default defineComponent({
  name: 'FileInput',
  emits: ['upload', 'purge'],
  props: {
    control: {
      type: Object as PropType<FormControl<any>>,
      default: () => new FormControl({ name: 'file', label: 'Upload', type: 'file', mime: 'pdf' })
    },
    uniqueId: { type: String, default: () => uniqueId() }
  },
  computed: {
    mimeType() {
      return this.control?.mime || 'pdf';
    }
  },
  methods: {
    openDialog(event: any) {
      event.target.value = '';
      if (this.control.disabled || this.control.value) event.preventDefault();
    },
    upload(event: Event) {
      const files = (event.target as HTMLInputElement).files;
      this.$emit('upload', { name: this.control.name, file: files?.length && files[0] });
    }
  }
});
