import { Module } from 'vuex';

import { RootState } from '@/store';
import { Gallery } from '@/services/galleries/galleries.types';
import { galleriesService } from '@/services/galleries/galleries.service';
import { Form } from '@/services/forms/form.class';
import { Contact } from '@/services/contacts/contacts.types';
import { contactsService } from '@/services/contacts/contacts.service';
import { Project } from '@/services/projects/projects.types';
import { projectsService } from '@/services/projects/projects.service';
import { artistsService } from '@/services/artists/artists.service';
import { Artist } from '@/services/artists/artists.types';
import { ListResult, PaginationOptions } from '@/core/axios.types';

export interface GallerieState {
  gallery: Gallery;
  galleryLoading: boolean;
  galleries: ListResult<Gallery>;
  galleriesLoading: boolean;
  search: string;
  pagination: PaginationOptions;
}

const state: GallerieState = {
  gallery: null,
  galleryLoading: false,
  galleries: null,
  galleriesLoading: false,
  search: null,
  pagination: {
    page: 1,
    limit: 200,
    sort_by: null,
    sort_dir: 'desc'
  }
};

const options: Module<GallerieState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setGallery: ({ commit, dispatch }, gallery: Gallery | string | number): Promise<void> => {
      if (typeof gallery === 'number' || typeof gallery === 'string') return dispatch('getGallery', gallery);
      else commit('gallery', gallery);
    },
    getGallery: ({ commit, dispatch }, id: number | string): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .get(+id)
        .then(gallery => gallery && commit('gallery', gallery))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    updateGallery: ({ commit, dispatch }, gallery: Gallery): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .update(gallery.id, gallery)
        .then(g => g && commit('gallery', g))
        .then(() => dispatch('alert/pushSuccess', 'Gallery successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    updateGalleryFile: ({ state, commit, dispatch }, files: Record<string, File>): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .updateFiles(state.gallery.id, files)
        .then(gallery => gallery && commit('gallery', gallery))
        .then(() => dispatch('alert/pushSuccess', 'Gallery successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    newContact: ({ state, commit, dispatch }, gallery: Partial<Gallery>): Promise<void> => {
      commit('galleryLoading', true);
      return contactsService
        .new()
        .then(
          contact =>
            contact &&
            commit('gallery', { ...state.gallery, ...gallery, contacts: [...state.gallery.contacts, contact] })
        )
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    updateNthContact: async (
      { commit },
      { index, contact, gallery }: { index: number; contact: Partial<Contact>; gallery: Partial<Gallery> }
    ): Promise<void> => {
      const contacts = [
        ...state.gallery.contacts.filter((_c, idx) => idx < index),
        { ...state.gallery.contacts[index], ...contact },
        ...state.gallery.contacts.filter((_c, idx) => idx > index)
      ];
      commit('gallery', { ...state.gallery, ...gallery, contacts });
    },
    deleteNthContact: async (
      { commit },
      { index, gallery }: { index: number; gallery: Partial<Gallery> }
    ): Promise<void> => {
      const contacts = [
        ...state.gallery.contacts.filter((_c, idx) => idx < index),
        { ...state.gallery.contacts[index], _destroy: '1' },
        ...state.gallery.contacts.filter((_c, idx) => idx > index)
      ];
      commit('gallery', { ...state.gallery, ...gallery, contacts });
    },
    updateProject: ({ commit, dispatch, state }, project: Project): Promise<void> => {
      commit('galleryLoading', true);
      return projectsService
        .update(project.id, project)
        .then(() => projectsService.get(project.id).then(p => p && commit('gallery', { ...state.gallery, project: p })))
        .then(() => dispatch('alert/pushSuccess', 'Project successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    updateProjectPicture: (
      { state, commit, dispatch },
      { file, project }: { file: File; project: Partial<Project> }
    ): Promise<void> => {
      const projectId = state.gallery?.project?.id;
      console.log(project);
      if (!projectId) return;
      commit('galleryLoading', true);
      return projectsService
        .updatePicture(projectId, file)
        .then(() =>
          projectsService.get(projectId).then(
            p =>
              p &&
              commit('gallery', {
                ...state.gallery,
                project: { ...p, ...project, key_work_picture: p.key_work_picture }
              })
          )
        )
        .then(() => dispatch('alert/pushSuccess', 'Project successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    deleteProjectPicture: ({ state, commit, dispatch }, project: Partial<Project>): Promise<void> => {
      const projectId = state.gallery?.project?.id;
      console.log(project);
      if (!projectId) return;
      commit('galleryLoading', true);
      return projectsService
        .deletePicture(projectId)
        .then(() =>
          projectsService.get(projectId).then(
            p =>
              p &&
              commit('gallery', {
                ...state.gallery,
                project: { ...p, ...project, key_work_picture: p.key_work_picture }
              })
          )
        )
        .then(() => dispatch('alert/pushSuccess', 'Project successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    createArtist: ({ state, commit, dispatch }, artist: Artist): Promise<void> => {
      if (!state.gallery?.project?.id) return;
      commit('galleryLoading', true);
      return artistsService
        .create({ ...artist, project_id: state.gallery.project.id })
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Artist successfully created!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    updateArtist: ({ commit, dispatch }, artist: Artist): Promise<void> => {
      commit('galleryLoading', true);
      return artistsService
        .update(artist.id, artist)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Artist successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    updateArtistFiles: (
      { commit, dispatch },
      { files, artist }: { files: Record<string, File>; artist: Artist }
    ): Promise<void> => {
      commit('galleryLoading', true);
      return artistsService
        .updateFiles(artist.id, files)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Artist successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    deleteArtist: ({ commit, dispatch }, artistId: number): Promise<void> => {
      commit('galleryLoading', true);
      return artistsService
        .delete(artistId)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Artist successfully deleted!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    deleteArtistBiography: ({ commit, dispatch }, artist: Artist): Promise<void> => {
      commit('galleryLoading', true);
      return artistsService
        .deleteBiography(artist.id)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Artist successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    addInvoice: ({ state, commit, dispatch }, file: File): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .addInvoice(state.gallery.id, file)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Invoice successfully uploaded!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    deleteInvoice: ({ state, commit, dispatch }, invoiceId: string): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .deleteInvoice(state.gallery.id, invoiceId)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Invoice successfully deleted!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    addInsurance: ({ state, commit, dispatch }, file: File): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .addInsurance(state.gallery.id, file)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Insurance successfully uploaded!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    deleteInsurance: ({ state, commit, dispatch }, insuranceId: string): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .deleteInsurance(state.gallery.id, insuranceId)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Insurance successfully deleted!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    addFormBadge: ({ state, commit, dispatch }, file: File): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .addFormBadge(state.gallery.id, file)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Badge form successfully uploaded!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    deleteFormBadge: ({ state, commit, dispatch }, formId: string): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .deleteFormBadge(state.gallery.id, formId)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Badge form successfully deleted!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    addFormShipping: ({ state, commit, dispatch }, file: File): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .addFormShipping(state.gallery.id, file)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Shipping form successfully uploaded!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    deleteFormShipping: ({ state, commit, dispatch }, formId: string): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .deleteFormShipping(state.gallery.id, formId)
        .then(() => dispatch('reloadGallery'))
        .then(() => dispatch('alert/pushSuccess', 'Shipping form successfully deleted!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    deleteBoothPlan: ({ state, commit, dispatch }): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .deleteBoothPlan(state.gallery?.id)
        .then(gallery => gallery && commit('gallery', gallery))
        .then(() => dispatch('alert/pushSuccess', 'Gallery successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    deleteBoothOrderForm: ({ state, commit, dispatch }): Promise<void> => {
      commit('galleryLoading', true);
      return galleriesService
        .deleteBoothOrderForm(state.gallery?.id)
        .then(gallery => gallery && commit('gallery', gallery))
        .then(() => dispatch('alert/pushSuccess', 'Gallery successfully updated!', { root: true }))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('galleryLoading', false));
    },
    reloadGallery: ({ state, dispatch }): Promise<void> => {
      return dispatch('setGallery', state.gallery.id);
    },
    clearGallery: ({ dispatch }): Promise<void> => {
      return dispatch('setGallery', null);
    },
    listGalleries({ commit, dispatch, getters }): Promise<void> {
      commit('galleriesLoading', true);
      return galleriesService
        .list(getters.queryOptions)
        .then(list => list && commit('galleries', list))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('galleriesLoading', false));
    },
    searchBy({ commit, dispatch }, search: string): Promise<void> {
      commit('search', search ? search : null);
      return dispatch('setPage', 1);
    },
    setPage({ commit, state, dispatch }, page: number): Promise<void> {
      commit('pagination', { ...state.pagination, page });
      return dispatch('listGalleries');
    },
    sortBy({ commit, state, dispatch }, key: keyof Gallery): Promise<void> {
      const pagination = state.pagination;
      if (pagination.sort_by !== key) commit('pagination', { ...pagination, sort_by: key });
      else commit('pagination', { ...pagination, sort_dir: pagination.sort_dir === 'desc' ? 'asc' : 'desc' });
      return dispatch('setPage', 1);
    }
  },
  mutations: {
    gallery: (state, gallery) => (state.gallery = gallery),
    galleryLoading: (state, loading) => (state.galleryLoading = loading),
    galleries: (state, list) => (state.galleries = list),
    galleriesLoading: (state, loading) => (state.galleriesLoading = loading),
    search: (state, text) => (state.search = text),
    pagination: (state, options) => (state.pagination = options)
  },
  getters: {
    queryOptions: state => ({
      filters: { fulltext: state.search },
      pagination: state.pagination
    }),
    galleriesChecklists: state => {
      return state.galleries?.values.map(gallery => {
        return {
          ...gallery,
          ...{
            has_curatorial_text: gallery.project?.desc?.length > 0,
            has_key_visual: gallery.project?.key_work_picture?.length > 0,
            has_badge_application: gallery.form_badges.length > 0,
            has_vehicle_access_application: gallery.form_shippings.length > 0,
            has_insurance: gallery.insurances.length > 0,
            has_floorplan: gallery.booth_plan?.length > 0,
            has_booth_order_form: gallery.booth_order_form?.length > 0,
            has_invoice: gallery.invoices?.length > 0
          }
        };
      });
    },
    galleryForm: state => {
      if (!state.gallery) return;

      return new Form<Gallery>([
        {
          name: 'gallery',
          class: 'row',
          controls: [
            {
              name: 'name',
              label: 'Gallery name',
              type: 'text',
              value: state.gallery.name,
              class: 'col-4',
              required: true
            },
            {
              name: 'city',
              label: 'City',
              type: 'text',
              value: state.gallery.city,
              class: 'col-4',
              required: true
            },
            {
              name: 'website',
              label: 'Website',
              type: 'text',
              value: state.gallery.website,
              class: 'col-4',
              required: true
            }
          ]
        }
      ]);
    },
    contactsForms: state => {
      if (!(state.gallery?.contacts?.length > 0)) return;

      return state.gallery?.contacts.map(
        contact =>
          new Form<Contact>([
            {
              name: 'contact',
              class: 'row',
              controls: contact._destroy
                ? [
                    {
                      name: '_destroy',
                      type: 'text',
                      value: '1',
                      hidden: true
                    }
                  ]
                : [
                    {
                      name: 'first_name',
                      label: 'First name',
                      type: 'text',
                      value: contact.first_name,
                      class: 'col',
                      required: true
                    },
                    {
                      name: 'last_name',
                      label: 'Last name',
                      type: 'text',
                      value: contact.last_name,
                      class: 'col',
                      required: true
                    },
                    {
                      name: 'email',
                      label: 'Email',
                      type: 'text',
                      value: contact.email,
                      class: 'col',
                      required: true
                    },
                    {
                      name: 'country',
                      label: 'Country',
                      type: 'text',
                      value: contact.country,
                      class: 'col',
                      required: true
                    },
                    {
                      name: 'city',
                      label: 'City',
                      type: 'text',
                      value: contact.city,
                      class: 'col',
                      required: true
                    }
                  ]
            }
          ])
      );
    },
    projectForm: state => {
      if (!state.gallery?.project) return;

      return new Form<Project>([
        {
          name: 'project',
          controls: [
            {
              name: 'desc',
              label: 'A curatorial project text (between 450-1525 characters in English)',
              type: 'text-multiline',
              value: state.gallery.project.desc,
              minlength: 450,
              maxlength: 1525,
              required: true
            }
          ]
        },
        {
          name: 'key_work',
          style: [
            'display: grid',
            'grid-template-columns: repeat(2, 1fr)',
            'grid-template-rows: repeat(5, 1fr)',
            'column-gap: 1rem'
          ].join(';'),
          controls: [
            {
              name: 'key_work_picture',
              label:
                'Key visual. Please select one image as the key visual for your gallery page on our website and your catalogue page for Asia NOW 2024 (300 dpi min.)',
              type: 'visual',
              value: state.gallery.project.key_work_picture,
              style: 'grid-row: 1 / 6',
              required: true
            },
            {
              name: 'key_work_artist',
              label: 'Artist name',
              type: 'text',
              value: state.gallery.project.key_work_artist,
              required: true
            },
            {
              name: 'key_work_name',
              label: 'Work name',
              type: 'text',
              value: state.gallery.project.key_work_name,
              required: true
            },
            {
              name: 'key_work_year',
              label: 'Year',
              type: 'text',
              value: state.gallery.project.key_work_year
            },
            {
              name: 'key_work_technique',
              label: 'Technique',
              type: 'text',
              value: state.gallery.project.key_work_technique,
              required: true
            },
            {
              name: 'key_work_dimensions',
              label: 'Dimensions',
              type: 'text',
              value: state.gallery.project.key_work_dimensions,
              class: 'mb-0',
              required: true
            }
          ]
        }
      ]);
    }
  }
};

export default options;
