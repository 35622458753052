
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { AlertMessageTheme } from './AlertMessage.types';
import { AlertAction } from '../AlertFeed/AlertFeed.types';

export default defineComponent({
  name: 'AlertMessage',
  props: {
    content: {
      type: String,
      default: null
    },
    theme: {
      type: String as PropType<AlertMessageTheme>,
      default: 'primary'
    },
    icon: {
      type: String,
      default: null
    },
    duration: {
      type: Number,
      default: null
    },
    action: {
      type: Object as PropType<AlertAction>,
      default: null
    },
    rate: {
      type: Number,
      default: 10
    },
    dismissable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: +this.duration * 1000,
      meta: null
    };
  },
  computed: {
    ...mapGetters('global-settings', ['stocks']),
    timerWidth(): string | number {
      if (!this.timer) return 0;
      return this.timer / this.duration / 10 + '%';
    },
    iconClass(): string {
      if (this.icon) return this.icon;
      switch (this.theme) {
        case 'primary':
          return 'fa-solid fa-info-circle';
        case 'success':
          return 'fa-solid fa-circle-check';
        case 'warning':
          return 'fa-solid fa-triangle-exclamation';
        case 'danger':
          return 'fa-solid fa-exclamation-circle';
        default:
          return '';
      }
    }
  },
  methods: {
    setupTimer() {
      if (this.timer) {
        interval(this.rate)
          .pipe(takeWhile(() => this.timer > 0))
          .subscribe(() => {
            this.timer = this.timer - this.rate;
          });
      }
    }
  },
  mounted() {
    this.setupTimer();
  }
});
