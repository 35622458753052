
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessage from '@/components/Alerts/AlertMessage/AlertMessage.vue';
import CustomBtn from '@/components/Misc/CustomBtn/CustomBtn.vue';
import FileInput from '@/components/Forms/FileInput/FileInput.vue';
import FormCpnt from '@/components/Forms/Form/Form.vue';
import SectionTitle from '@/components/Sections/SectionTitle/SectionTitle.vue';
import SectionHeader from '@/components/Sections/SectionHeader/SectionHeader.vue';
import { FormControl } from '@/services/forms/form-control.class';
import { datesService } from '@/services/dates/dates.service';
import { FormBadge } from '@/services/galleries/galleries.types';

export default defineComponent({
  name: 'BadgeView',
  components: { AlertMessage, CustomBtn, FileInput, FormCpnt, SectionTitle, SectionHeader },
  computed: {
    ...mapState('content', ['content']),
    ...mapState('gallery', ['gallery']),
    ...mapGetters('auth', ['isGallery']),
    newBadgeControl() {
      return new FormControl({
        name: 'new-badge',
        label: 'Upload the form',
        type: 'file',
        value: null
      });
    }
  },
  methods: {
    ...mapActions('content', ['setContent', 'clearContent']),
    ...mapActions('gallery', ['addFormBadge', 'deleteFormBadge']),
    badgeForm(badge: FormBadge) {
      return new FormControl({
        name: `badge-${badge.id}`,
        label: `Form uploaded on ${datesService.format(badge.created_at)}`,
        type: 'file',
        value: badge.pdf,
        deletable: this.isGallery,
        class: 'col-4'
      });
    },
    handleDownload() {
      window.open(
        process.env.VUE_APP_PUBLIC_BUCKET_URL + '/2024%20EDITION%20-%20FORMS/2_Exhibitor%20badges.pdf',
        '_blank'
      ); //to open in new tab
    },
    handleUpload(event: { name: string; file: File }) {
      if (event.name === 'new-badge' && !!event.file) this.addFormBadge(event.file);
    }
  },
  mounted() {
    this.setContent('badge');
  },
  unmounted() {
    this.clearContent();
  }
});
