
import { defineComponent, PropType } from 'vue';

import { SpinnerPlaceholderAlign } from './SpinnerPlaceholder.types';

export default defineComponent({
  name: 'SpinnerPlaceholder',
  props: {
    icon: {
      type: String,
      default: 'fas fa-spinner'
    },
    align: {
      type: String as PropType<SpinnerPlaceholderAlign>,
      default: 'center'
    }
  }
});
