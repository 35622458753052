
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

import SectionTitle from '@/components/Sections/SectionTitle/SectionTitle.vue';
import SectionHeader from '@/components/Sections/SectionHeader/SectionHeader.vue';

export default defineComponent({
  name: 'ServiceView',
  components: { SectionTitle, SectionHeader },
  computed: {
    ...mapState('content', ['content'])
  },
  methods: {
    ...mapActions('content', ['setContent', 'clearContent'])
  },
  mounted() {
    this.setContent('service');
  },
  unmounted() {
    this.clearContent();
  }
});
