
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessage from '@/components/Alerts/AlertMessage/AlertMessage.vue';
import CustomBtn from '@/components/Misc/CustomBtn/CustomBtn.vue';
import FileInput from '@/components/Forms/FileInput/FileInput.vue';
import FormCpnt from '@/components/Forms/Form/Form.vue';
import SectionTitle from '@/components/Sections/SectionTitle/SectionTitle.vue';
import SectionHeader from '@/components/Sections/SectionHeader/SectionHeader.vue';
import { FormControl } from '@/services/forms/form-control.class';
import { datesService } from '@/services/dates/dates.service';
import { Insurance } from '@/services/galleries/galleries.types';

export default defineComponent({
  name: 'InsuranceView',
  components: { AlertMessage, CustomBtn, FileInput, FormCpnt, SectionTitle, SectionHeader },
  computed: {
    ...mapState('content', ['content']),
    ...mapState('gallery', ['gallery']),
    ...mapGetters('auth', ['isGallery']),
    newInsuranceControl() {
      return new FormControl({
        name: 'new-insurance',
        label: 'Upload new proof of insurance',
        type: 'file',
        value: null
      });
    }
  },
  methods: {
    ...mapActions('content', ['setContent', 'clearContent']),
    ...mapActions('gallery', ['addInsurance', 'deleteInsurance']),
    insuranceForm(insurance: Insurance) {
      return new FormControl({
        name: `insurance-${insurance.id}`,
        label: `Proof of insurance uploaded on ${datesService.format(insurance.created_at)}`,
        type: 'file',
        value: insurance.pdf,
        deletable: this.isGallery,
        class: 'col-4'
      });
    },
    handleDownload() {
      window.open(
        process.env.VUE_APP_PUBLIC_BUCKET_URL + '/2024%20EDITION%20-%20FORMS/4_Confirmation%20of%20insurance.pdf',
        '_blank'
      ); //to open in new tab
    },
    handleUpload(event: { name: string; file: File }) {
      if (event.name === 'new-insurance' && !!event.file) this.addInsurance(event.file);
    }
  },
  mounted() {
    this.setContent('insurance');
  },
  unmounted() {
    this.clearContent();
  }
});
