
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'GalleryView',
  components: {},
  computed: {
    ...mapState('gallery', ['gallery'])
  },
  methods: {
    ...mapActions('alert', ['pushError']),
    ...mapActions('gallery', ['setGallery'])
  },
  mounted() {
    if (!this.$route.params.id) {
      this.pushError({ error: new Error('Unknown gallery id'), bubble: false });
    }

    this.setGallery(this.$route.params.id).finally(() => {
      if (!this.gallery) {
        this.pushError({
          error: new Error('An error occurred while retrieving information from the gallery'),
          bubble: false
        });
      }
    });
  }
});
