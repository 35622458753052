
import { defineComponent, PropType } from 'vue';

import { ModalLayoutType, ModalLayoutSize } from './ModalLayout.types';

export default defineComponent({
  name: 'ModalLayout',
  props: {
    layout: {
      type: String as PropType<ModalLayoutType>,
      default: 'single'
    },
    size: {
      type: String as PropType<ModalLayoutSize>,
      default: 'md'
    }
  },
  computed: {
    layoutClass() {
      return this.layout ? `layout-${this.layout}` : '';
    },
    sizeClass() {
      return this.size ? `size-${this.size}` : '';
    }
  }
});
