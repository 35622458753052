
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessage from '@/components/Alerts/AlertMessage/AlertMessage.vue';
import ArtistCard from '@/components/Artists/ArtistCard/ArtistCard.vue';
import ContactCard from '@/components/Contacts/ContactCard/ContactCard.vue';
import CustomBtn from '@/components/Misc/CustomBtn/CustomBtn.vue';
import FormCpnt from '@/components/Forms/Form/Form.vue';
import SectionTitle from '@/components/Sections/SectionTitle/SectionTitle.vue';
import SectionHeader from '@/components/Sections/SectionHeader/SectionHeader.vue';
import { some } from 'lodash';

export default defineComponent({
  name: 'ProjectView',
  components: { AlertMessage, ArtistCard, ContactCard, CustomBtn, FormCpnt, SectionTitle, SectionHeader },
  data() {
    return {
      activeTab: 'general'
    };
  },
  computed: {
    ...mapState('gallery', ['gallery']),
    ...mapState('artist', ['artist']),
    ...mapGetters('gallery', ['galleryForm', 'projectForm', 'contactsForms']),
    sectionFilledGeneral() {
      return (
        !!this.gallery?.name && !!this.gallery?.city && !!this.gallery?.website && this.gallery?.contacts?.length > 0
      );
    },
    sectionFilledProject() {
      return (
        !!this.gallery?.project?.desc &&
        !!this.gallery?.project?.key_work_picture &&
        !!this.gallery?.project?.key_work_artist &&
        !!this.gallery?.project?.key_work_name &&
        !!this.gallery?.project?.key_work_year &&
        !!this.gallery?.project?.key_work_technique &&
        !!this.gallery?.project?.key_work_dimensions
      );
    },
    sectionFilledArtists() {
      return (
        this.gallery?.project?.artists?.length > 0 &&
        some(
          this.gallery?.project?.artists,
          artist =>
            !!artist.first_name &&
            !!artist.last_name &&
            !!artist.gender &&
            !!artist.country &&
            !!artist.biography &&
            artist.works?.length > 0 &&
            some(
              artist.works,
              work => !!work.name && !!work.year && !!work.picture && !!work.technique && !!work.dimensions
            )
        )
      );
    }
  },
  methods: {
    ...mapActions('gallery', [
      'updateGallery',
      'updateProject',
      'updateProjectFiles',
      'deleteProjectPicture',
      'updateProjectPicture',
      'createArtist',
      'newContact',
      'updateNthContact',
      'deleteNthContact'
    ]),
    saveGallery() {
      const everythingIsValid =
        this.galleryForm.validate() &&
        this.contactsForms?.length > 0 &&
        this.contactsForms?.reduce((prev, curr) => prev && curr.validate(), true);
      if (everythingIsValid)
        this.updateGallery({
          ...this.gallery,
          ...this.galleryForm.value,
          contacts_attributes: this.contactsForms.map((form, index) => ({
            ...this.gallery.contacts[index],
            ...form.value
          }))
        });
    },
    saveProject() {
      if (this.projectForm.validate()) this.updateProject({ ...this.gallery?.project, ...this.projectForm.value });
    },
    handleUpload(event: { name: string; file: File }) {
      if (event.name === 'key_work_picture' && !!event.file)
        this.updateProjectPicture({ file: event.file, project: this.projectForm.value });
    }
  }
});
