import { get } from 'lodash';
import { Subject } from 'rxjs';

import { FormControl } from './form-control.class';
import { FormGroup } from './form-group.class';
import { FormGroupRaw } from './form-group.types';

export class Form<T> {
  groups: FormGroup<T>[];
  valid: boolean;
  change$: Subject<void>; // Will be triggered every time the value of a control changes

  get value(): T {
    return this.groups.reduce((value, group) => {
      if (group.flat) value = { ...value, ...group.value };
      else value[group.name] = group.value;
      return value;
    }, {} as any);
  }

  constructor(groups: FormGroupRaw<T>[] = []) {
    this.valid = false;
    this.change$ = new Subject();
    this.groups = groups.map(group => new FormGroup(group, this));
    this.groups.forEach(group => group.initAfterChange());
  }

  has(path: string): boolean {
    const [groupName, controlName] = path.split('.');
    const formGroup = this.groups.find(fg => fg.name === groupName);
    if (!formGroup) return false;
    return formGroup.has(controlName);
  }

  get(path: string): FormControl<T> {
    const [groupName, controlName] = path.split('.');
    const formGroup = this.groups.find(fg => fg.name === groupName);
    if (!formGroup) throw new Error(`Cannot find form group ${groupName}`);
    return formGroup.get(controlName);
  }

  validate(): boolean {
    if (!this.groups) return (this.valid = false);
    else if (this.groups.length === 0) return (this.valid = true);
    else return (this.valid = this.groups.reduce((isValid, control) => isValid && control.validate(), true));
  }

  reset(init?: Partial<T>) {
    if (!this.groups?.length) return;

    this.groups.forEach(group => {
      if (!group.controls?.length) return;

      group.controls.forEach(control => {
        const value = get(init, `${group.name}.${control.name}`);
        control.value = value;
      });
    });
  }
}
