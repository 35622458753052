import axios from '@/core/axios';
import { ListResult } from '@/core/axios.types';
import { AppExternalError } from '@/core/errors/app-external-error.class';

import { Contact, ContactsListOptions } from './contacts.types';

export class ContactsService {
  list(opts: ContactsListOptions = {}): Promise<ListResult<Contact>> {
    const { filters, pagination } = opts;

    return axios
      .get<ListResult<Contact>>(`/contacts`, {
        params: { ...filters, ...pagination }
      })
      .then(resp => resp?.data);
  }

  get(contactId: number): Promise<Contact> {
    return axios.get<Contact>(`/contacts/${contactId}`).then(resp => resp?.data);
  }

  new(): Promise<Contact> {
    return axios.get<Contact>(`/contacts/new`).then(resp => resp?.data);
  }

  create(contact: Contact & { gallery_id: number }): Promise<Contact> {
    return axios
      .post<Contact>(`/contacts`, { contact })
      .then(resp => resp?.data)
      .catch(error => {
        if (error?.response?.data?.email) throw new AppExternalError('Email field is invalid or already exists');
        else throw error;
      });
  }

  update(contactId: number | string, contact: Partial<Contact>): Promise<Contact> {
    if (!contactId) throw new Error('Missing contact id in update');
    return axios.put<Contact>(`contacts/${contactId}`, { contact }).then(resp => resp?.data);
  }

  delete(contactId: number | string): Promise<void> {
    if (!contactId) throw new Error('Missing contact id in delete');
    return axios.delete<void>(`contacts/${contactId}`).then(resp => resp?.data);
  }
}

export const contactsService = new ContactsService();
