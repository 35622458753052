import axios, { AxiosInstance } from 'axios';
import { get } from 'lodash';

import store from '@/store';
import router from '@/router';
import { SessionExpiredError } from '@/services/auth/auth.types';

function axiosProvider(): AxiosInstance {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
  });

  instance.interceptors.request.use(config => {
    const token = store.state.auth.token;
    config.headers.Authorization = token;
    return config;
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      const status = get(error, 'response.status');
      const data = get(error, 'response.data');
      const url = get(error, 'config.url');
      if (status === 401 && url !== '/login') {
        store.dispatch('auth/signOut');
        router.push('/login');
        return store.dispatch('alert/pushError', { error: new SessionExpiredError() });
      } else if (data && data.message) return Promise.reject(data);
      else return Promise.reject(error);
    }
  );

  return instance;
}

export default axiosProvider();
