import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bbed0a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-holder-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.modal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal-backdrop fade", { show: !!_ctx.modal }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)))
        }, null, 2),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modal.component), {
          class: "modal-component-content",
          onClose: _ctx.hideModal
        }, null, 40, ["onClose"]))
      ]))
    : _createCommentVNode("", true)
}