import axios from '@/core/axios';

import { Content } from './contents.types';

export class ContentsService {
  get(section: string): Promise<Content> {
    return axios.get<Content>(`/contents/${section}`).then(resp => resp?.data);
  }

  update(section: string, content: Partial<Content>): Promise<Content> {
    if (!section) throw new Error('Missing content section in update');
    return axios.put<Content>(`contents/${section}`, { content }).then(resp => resp?.data);
  }
}

export const contentsService = new ContentsService();
