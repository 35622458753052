import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-706d1e1f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-layout-container" }
const _hoisted_2 = {
  key: 1,
  class: "modal-layout-content"
}
const _hoisted_3 = { class: "modal-layout-left" }
const _hoisted_4 = { class: "modal-layout-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-layout", [_ctx.layoutClass, _ctx.sizeClass]])
    }, [
      _renderSlot(_ctx.$slots, "top", {}, undefined, true),
      (_ctx.layout === 'single')
        ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
        : _createCommentVNode("", true),
      (_ctx.layout === 'double')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "left", {}, undefined, true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "right", {}, undefined, true)
            ])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "bottom", {}, undefined, true)
    ], 2)
  ]))
}