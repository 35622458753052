import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a2c7406"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-container" }
const _hoisted_2 = {
  key: 0,
  class: "gallery-name-container"
}
const _hoisted_3 = {
  key: 1,
  class: "logo-container"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "items-container vstack gap-2 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_sidebar_item = _resolveComponent("custom-sidebar-item")!
  const _component_user_settings_box = _resolveComponent("user-settings-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showGallery && _ctx.gallery)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.gallery.name), 1))
      : _createCommentVNode("", true),
    (_ctx.showLogo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "logo",
            src: _ctx.logoUrl
          }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_custom_sidebar_item, {
          key: item.route,
          item: item,
          active: item.route === _ctx.$route.name,
          disabled: _ctx.disableSection(item),
          onClick: ($event: any) => (_ctx.openPage(item))
        }, null, 8, ["item", "active", "disabled", "onClick"]))
      }), 128))
    ]),
    (_ctx.showUser)
      ? (_openBlock(), _createBlock(_component_user_settings_box, {
          key: 2,
          class: "mt-auto"
        }))
      : _createCommentVNode("", true)
  ]))
}