class NavigationService {
  private routes = {
    /**
     * ADMIN
     */

    'admin-overview': {
      label: 'Overview',
      icon: 'fa-solid fa-info-circle'
    },
    'admin-galleries': {
      label: 'List of galleries',
      icon: 'fa-solid fa-list'
    },
    'admin-content': {
      label: 'Content',
      icon: 'fa-solid fa-file-pen'
    },

    /**
     * GALLERY
     */

    'gallery-overview': {
      label: 'Overview',
      icon: 'fa-solid fa-info-circle'
    },
    'gallery-project': {
      label: 'Curatorial project & key visuals',
      icon: 'fa-solid fa-image'
    },
    'gallery-pricing': {
      label: 'Pricing & invoicing',
      icon: 'fa-solid fa-dollar-sign'
    },
    'gallery-booth': {
      label: 'Booth planning',
      icon: 'fa-solid fa-location-dot'
    },
    'gallery-badge': {
      label: 'Badge & fair access',
      icon: 'fa-solid fa-id-badge'
    },
    'gallery-shipping': {
      label: 'Location & shipping',
      icon: 'fa-solid fa-truck'
    },
    'gallery-service': {
      label: 'Service',
      icon: 'fa-solid fa-user-check'
    },
    'gallery-insurance': {
      label: 'Insurance',
      icon: 'fa-solid fa-shield-halved'
    },
    'gallery-broadcast': {
      label: 'Online Viewing Room',
      icon: 'fa-solid fa-tv-retro'
    },
    'gallery-contact': {
      label: 'Contact us',
      icon: 'fa-solid fa-comment'
    },

    /**
     * GALLERY IN ADMIN
     */

    'admin-gallery-project': {
      label: 'Curatorial project & key visuals',
      icon: 'fa-solid fa-image'
    },
    'admin-gallery-pricing': {
      label: 'Pricing & invoicing',
      icon: 'fa-solid fa-dollar-sign'
    },
    'admin-gallery-booth': {
      label: 'Booth planning',
      icon: 'fa-solid fa-location-dot'
    },
    'admin-gallery-badge': {
      label: 'Badge & fair access',
      icon: 'fa-solid fa-id-badge'
    },
    'admin-gallery-shipping': {
      label: 'Location & shipping',
      icon: 'fa-solid fa-truck'
    },
    'admin-gallery-service': {
      label: 'Service',
      icon: 'fa-solid fa-user-check'
    },
    'admin-gallery-insurance': {
      label: 'Insurance',
      icon: 'fa-solid fa-shield-halved'
    },
    'admin-gallery-broadcast': {
      label: 'Online Viewing Room',
      icon: 'fa-solid fa-tv-retro'
    },
    'admin-gallery-contact': {
      label: 'Contact us',
      icon: 'fa-solid fa-comment'
    }
  };

  filterItems(children, allowed: string[]) {
    return children
      .filter(route => allowed.indexOf(route.name) > -1)
      .map(route => ({ ...this.routes[route.name], route: route.name }));
  }
}

export const navigationService = new NavigationService();
