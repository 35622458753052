
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessage from '@/components/Alerts/AlertMessage/AlertMessage.vue';
import CustomBtn from '@/components/Misc/CustomBtn/CustomBtn.vue';
import FormCpt from '@/components/Forms/Form/Form.vue';
import { AuthenticationError, Credentials } from '@/services/auth/auth.types';
import { Form } from '@/services/forms/form.class';

export default defineComponent({
  name: 'LoginView',
  components: { AlertMessage, CustomBtn, FormCpt },
  data() {
    return {
      authErrorAlert: null
    };
  },
  computed: {
    ...mapState('auth', ['authenticatedUser', 'signingIn']),
    ...mapGetters('auth', ['isAuthenticated', 'isAdmin', 'isAnow', 'isGallery', 'authenticatedGalleryId']),
    logoUrl() {
      return process.env.VUE_APP_PUBLIC_BUCKET_URL + '/logo_transparent.png';
    },
    loginImgStyle() {
      return `background-image: url('${process.env.VUE_APP_PUBLIC_BUCKET_URL}/cover.jpg');`;
    },
    loginForm() {
      return new Form<Credentials>([
        {
          name: 'login',
          class: 'w-100',
          controls: [
            {
              name: 'email',
              label: 'Email address',
              type: 'text',
              required: true
            },
            {
              name: 'password',
              label: 'Password',
              type: 'password',
              required: true
            }
          ]
        }
      ]);
    }
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    ...mapActions('alert', ['pushError']),
    logIn() {
      if (this.loginForm.validate()) {
        this.signIn(this.loginForm.value).catch(error => {
          if (!(error instanceof AuthenticationError)) return;
          this.authErrorAlert = {
            type: 'danger',
            text: error.message,
            duration: 5
          };
          setTimeout(() => (this.authErrorAlert = null), this.authErrorAlert.duration * 1000);
        });
      }
    }
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      handler(isAuthenticated: boolean) {
        if (!isAuthenticated) return;
        if (this.isAdmin || this.isAnow) this.$router.push({ name: 'admin' });
        else if (this.isGallery) {
          if (this.authenticatedGalleryId) {
            this.$router.push({ name: 'gallery', params: { id: this.authenticatedGalleryId } });
          } else {
            this.pushError({ error: new Error('Your account is not associated with any gallery'), bubble: false });
          }
        }
      }
    }
  }
});
