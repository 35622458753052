
import { defineComponent, PropType } from 'vue';
import { Editor } from '@tiptap/vue-3';

import CustomBtn from '@/components/Misc/CustomBtn/CustomBtn.vue';

export default defineComponent({
  name: 'EditorMenu',
  emits: ['save'],
  components: { CustomBtn },
  props: {
    editor: {
      type: Object as PropType<Editor>,
      default: () => null
    }
  },
  computed: {},
  methods: {
    addImage() {
      const url = window.prompt('URL');

      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run();
      }
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      // cancelled
      if (url === null) return;

      // empty
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }

      // update link
      this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    }
  }
});
