import axios from '@/core/axios';
import { omit } from 'lodash';

import { Project } from './projects.types';

export class ProjectsService {
  get(projectId: number): Promise<Project> {
    return axios.get<Project>(`/projects/${projectId}`).then(resp => resp?.data);
  }

  update(projectId: number | string, obj: Partial<Project>): Promise<Project> {
    if (!projectId) throw new Error('Missing project id in update');

    /* Filtering out picture attachment */
    const project = omit(obj, 'key_work_picture');

    return axios.put<Project>(`projects/${projectId}`, { project }).then(resp => resp?.data);
  }

  updatePicture(projectId: number | string, file: File): Promise<Project> {
    if (!projectId) throw new Error('Missing project id in updateFiles');

    const formData = new FormData();
    formData.append('project[key_work_picture]', file);

    return axios
      .post<Project>(`projects/${projectId}/update-picture`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  deletePicture(projectId: number | string): Promise<Project> {
    if (!projectId) throw new Error('Missing project id in deletePicture');

    return axios.delete<Project>(`projects/${projectId}/delete-picture`).then(resp => resp?.data);
  }
}

export const projectsService = new ProjectsService();
