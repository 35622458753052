
import { defineComponent, PropType } from 'vue';
import { some } from 'lodash';

import FormControlCpnt from '@/components/Forms/FormControl/FormControl.vue';
import { FormGroup } from '@/services/forms/form-group.class';
import { FormControlSize } from '@/services/forms/form-control.types';

export default defineComponent({
  name: 'FormGroupCpnt',
  components: { FormControlCpnt },
  emits: ['upload', 'purge'],
  props: {
    group: {
      type: Object as PropType<FormGroup<unknown>>,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<FormControlSize>,
      default: 'md'
    }
  },
  computed: {
    showGroup() {
      return some(this.group?.controls, c => !c.hidden);
    },
    groupSize() {
      return this.group.size || this.size;
    }
  }
});
