import { Module } from 'vuex';

import { RootState } from '@/store';
import { Message } from '@/services/messages/messages.types';
import { messagesService } from '@/services/messages/messages.service';

export interface MessageState {
  messages: Message[];
  messagesLoading: boolean;
}

const state: MessageState = {
  messages: [],
  messagesLoading: false
};

const options: Module<MessageState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    indexMessages: ({ commit, dispatch }, galleryId: number): Promise<void> => {
      commit('messagesLoading', true);
      return messagesService
        .index(galleryId)
        .then(messages => messages && commit('messages', messages))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('messagesLoading', false));
    },
    createMessage: (
      { state, commit, dispatch },
      { galleryId, message }: { galleryId: number; message: Partial<Message> }
    ): Promise<void> => {
      return messagesService
        .create(galleryId, message)
        .then(message => message && commit('messages', [...state.messages, message]))
        .catch(error => dispatch('alert/pushError', { error }, { root: true }))
        .finally(() => commit('messagesLoading', false));
    }
  },
  mutations: {
    messages: (state, messages) => (state.messages = messages),
    messagesLoading: (state, loading) => (state.messagesLoading = loading)
  }
};

export default options;
