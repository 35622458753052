
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { Artist } from '@/services/artists/artists.types';
import { Form } from '@/services/forms/form.class';
import { Work } from '@/services/works/works.types';
import CardBtn from '@/components/Misc/CardBtn/CardBtn.vue';
import FormCpnt from '@/components/Forms/Form/Form.vue';
import WorkCard from '@/components/Works/WorkCard/WorkCard.vue';

export default defineComponent({
  name: 'ArtistCard',
  components: { CardBtn, FormCpnt, WorkCard },
  props: {
    artist: {
      type: Object as PropType<Artist>,
      default: () => null
    }
  },
  computed: {
    ...mapGetters('auth', ['isAnow']),
    filledArtworks() {
      return this.artist?.works?.filter(work => {
        return !!work.name && !!work.year && !!work.picture && !!work.technique && !!work.dimensions;
      });
    },
    artistForm() {
      if (!this.artist) return null;
      return new Form<Artist>([
        {
          name: 'artist',
          class: 'row',
          controls: [
            {
              name: 'first_name',
              label: 'First name',
              type: 'text',
              value: this.artist.first_name,
              required: true,
              class: 'col-3'
            },
            {
              name: 'last_name',
              label: 'Last name',
              type: 'text',
              value: this.artist.last_name,
              required: true,
              class: 'col-3'
            },
            {
              name: 'gender',
              label: 'Gender',
              type: 'text',
              value: this.artist.gender,
              required: true,
              class: 'col-3'
            },
            {
              name: 'country',
              label: 'Nationality',
              type: 'text',
              value: this.artist.country,
              required: true,
              class: 'col-3'
            },
            {
              name: 'biography',
              label: 'Biography',
              type: 'file',
              value: this.artist.biography,
              class: 'col-4'
            }
          ]
        }
      ]);
    }
  },
  methods: {
    ...mapActions('artist', ['setArtist']),
    ...mapActions('work', ['setWork', 'createWork', 'deleteWork']),
    ...mapActions('gallery', [
      'createArtist',
      'updateArtist',
      'updateArtistFiles',
      'deleteArtist',
      'deleteArtistBiography',
      'reloadGallery'
    ]),
    ...mapActions('modal', ['showModal']),
    update(value: Partial<Artist>) {
      if (!this.artist.id) return;
      if (this.artistForm.validate()) this.updateArtist({ ...this.artist, ...value });
    },
    remove() {
      if (!this.artist.id) return;
      this.showModal({
        component: 'ConfirmationModal',
        data: { message: 'You are about to delete this artist, do you confirm?' },
        onClose: async (isConfirmed: boolean) => isConfirmed && this.deleteArtist(this.artist.id)
      });
    },
    create() {
      if (this.artist.id) return;
      if (this.artistForm.validate()) this.createArtist(this.artistForm.value).then(() => this.setArtist(null));
    },
    cancel() {
      this.setArtist(null);
    },
    openEditWorkModal(work?: Work) {
      const promise = work ? this.setWork(work) : this.createWork({ artist_id: this.artist.id });
      promise.then(() =>
        this.showModal({
          component: 'EditWorkModal',
          data: { editOrCreate: work ? 'edit' : 'create' },
          onClose: async (hasEdited: boolean) => hasEdited && this.reloadGallery()
        })
      );
    },
    openDeleteWorkModal(work: Work) {
      this.showModal({
        component: 'ConfirmationModal',
        data: { message: 'You are about to delete this art work, do you confirm?' },
        onClose: async (isConfirmed: boolean) =>
          isConfirmed && this.deleteWork({ workId: work.id }).then(() => this.reloadGallery())
      });
    },
    handleUpload(event: { name: string; file: File }, artist: Artist) {
      if (event.name === 'biography' && !!event.file) {
        this.updateArtistFiles({ files: { [event.name]: event.file }, artist });
      }
    }
  }
});
