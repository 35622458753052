
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

import { Contact } from '@/services/contacts/contacts.types';
import { Form } from '@/services/forms/form.class';
import FormCpnt from '@/components/Forms/Form/Form.vue';

export default defineComponent({
  name: 'ContactCard',
  emits: ['update', 'delete'],
  components: { FormCpnt },
  props: {
    form: {
      type: Object as PropType<Form<Contact>>,
      default: () => null
    }
  },
  computed: {
    ...mapGetters('auth', ['isAnow']),
    willBeDeleted() {
      return this.form?.has('contact._destroy');
    }
  }
});
