
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SectionTitle',
  props: {
    icon: {
      type: String,
      default: () => null
    },
    text: {
      type: String,
      default: () => 'Section title'
    }
  }
});
