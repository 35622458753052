import { User } from '../users/users.types';
import { AppExternalError } from '@/core/errors/app-external-error.class';
import { AppInternalError } from '@/core/errors/app-internal-error.class';

export interface Credentials {
  email: string;
  password: string;
}

export interface TokenContent {
  exp: number;
}

export interface SignInResponse {
  user: User;
  token: string;
}

export class SessionExpiredError extends AppExternalError {
  constructor() {
    super('Your session has expired, please log in again.');
  }
}

export class AuthenticationError extends AppInternalError {
  constructor(message = 'Invalid email or password') {
    super(message);
  }
}
