import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29914f6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_control_cpnt = _resolveComponent("form-control-cpnt")!

  return (_ctx.showGroup)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["group-form", ['group-' + _ctx.groupSize]])
      }, [
        (_ctx.group.label)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "group-label",
              innerHTML: _ctx.group.label
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["group-controls w-100", _ctx.group.class]),
          style: _normalizeStyle(_ctx.group.style)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group.controls, (control) => {
            return (_openBlock(), _createBlock(_component_form_control_cpnt, {
              key: control.name,
              size: _ctx.size,
              control: control,
              disabled: _ctx.disabled,
              inline: _ctx.inline,
              onUpload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('upload', $event))),
              onPurge: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('purge', $event)))
            }, null, 8, ["size", "control", "disabled", "inline"]))
          }), 128))
        ], 6)
      ], 2))
    : _createCommentVNode("", true)
}