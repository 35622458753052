
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import AlertFeed from '@/components/Alerts/AlertFeed/AlertFeed.vue';
import ModalHolder from '@/components/Modals/ModalHolder/ModalHolder.vue';

export default defineComponent({
  name: 'App',
  components: { AlertFeed, ModalHolder },
  methods: {
    ...mapActions('auth', ['getSession'])
  },
  mounted() {
    this.getSession();
    console.log(process.env.VUE_APP_BACKEND_URL);
    console.log(process.env.VUE_APP_PUBLIC_BUCKET_URL);
    /**
     * A not-so-nice trick to fix the viewport size problem, see:
     * https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
     * https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
     * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/#css-custom-properties-the-trick-to-correct-sizing
     * */
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    });
  }
});
