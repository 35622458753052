
import { defineComponent, PropType } from 'vue';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { uniqueId } from 'lodash';

import { TextInputType } from './TextInput.types';
import { FormControlSize } from '@/services/forms/form-control.types';

export default defineComponent({
  name: 'TextInput',
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    type: {
      type: String as PropType<TextInputType>,
      default: 'text'
    },
    uniqueId: {
      type: String,
      default: uniqueId()
    },
    unit: {
      type: String,
      default: null
    },
    invalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: null
    },
    minlength: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    maxlength: {
      type: Number,
      default: null
    },
    step: {
      type: String,
      default: null
    },
    size: {
      type: String as PropType<FormControlSize>,
      default: 'md'
    }
  },
  data() {
    return {
      update$: new Subject<string>()
    };
  },
  watch: {
    invalid(newValue: boolean, oldValue: boolean) {
      if (newValue && !oldValue) {
        const input = this.$refs.input;
        if (input) input.focus();
      }
    }
  },
  mounted() {
    this.update$.pipe(distinctUntilChanged()).subscribe(text => this.$emit('edit', text));
  },
  unmounted() {
    this.update$.complete();
  }
});
