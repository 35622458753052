import axios from '@/core/axios';

import { Message } from './messages.types';

export class MessagesService {
  index(galleryId: number): Promise<Message[]> {
    return axios.get<Message[]>(`/galleries/${galleryId}/messages`).then(resp => resp?.data);
  }

  create(galleryId: number, message: Partial<Message>): Promise<Message> {
    return axios.post<Message>(`/galleries/${galleryId}/messages`, { message }).then(resp => resp?.data);
  }
}

export const messagesService = new MessagesService();
