
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import SpinnerPlaceholder from '@/components/Misc/SpinnerPlaceholder/SpinnerPlaceholder.vue';

export default defineComponent({
  name: 'LogoutView',
  components: { SpinnerPlaceholder },
  computed: {
    ...mapGetters('auth', ['isAuthenticated'])
  },
  methods: {
    ...mapActions('auth', ['signOut'])
  },
  mounted() {
    this.signOut();
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      handler(isAuthenticated: boolean) {
        if (!isAuthenticated) this.$router.push('/login');
      }
    }
  }
});
