
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'UserSettingsBox',
  computed: {
    ...mapGetters('auth', ['isGallery', 'authenticatedUserName', 'authenticatedUserRole'])
  }
});
