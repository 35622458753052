import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-436f3958"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-bar-container" }
const _hoisted_2 = { class: "search-bar-icon" }
const _hoisted_3 = {
  key: 0,
  class: "fas fa-search m-0"
}
const _hoisted_4 = {
  key: 1,
  class: "fa-pulse fas fa-spinner"
}
const _hoisted_5 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.pending)
        ? (_openBlock(), _createElementBlock("i", _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.pending)
        ? (_openBlock(), _createElementBlock("i", _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("input", {
      class: _normalizeClass(["search-bar-input form-control", ['form-control-' + _ctx.size]]),
      type: "text",
      placeholder: _ctx.placeholder,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search$.next(($event.target as HTMLInputElement).value)))
    }, null, 42, _hoisted_5)
  ]))
}