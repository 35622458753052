import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c17eb014"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-icon-container" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message-insert-container", [_ctx.theme]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["message-container", { dismissable: _ctx.dismissable }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.iconClass)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: _normalizeClass(["message-icon fa-lg", [_ctx.iconClass]])
            }, null, 2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: "message-content",
        innerHTML: _ctx.content
      }, null, 8, _hoisted_2),
      (_ctx.dismissable)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: "close-btn fas fa-times",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }))
        : _createCommentVNode("", true),
      (_ctx.action)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "btn btn-sm",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.action.callback && _ctx.action.callback(...args)))
          }, _toDisplayString(_ctx.action.label), 1))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.timer)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "time-bar-container",
          style: _normalizeStyle({ width: _ctx.timerWidth })
        }, null, 4))
      : _createCommentVNode("", true)
  ], 2))
}