
import { defineComponent } from 'vue';

import SectionTitle from '@/components/Sections/SectionTitle/SectionTitle.vue';

import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'OverviewView',
  components: { SectionTitle },
  computed: {
    ...mapState('content', ['content'])
  },
  methods: {
    ...mapActions('content', ['setContent', 'clearContent'])
  },
  mounted() {
    this.setContent('overview');
  },
  unmounted() {
    this.clearContent();
  }
});
