
import { defineComponent, PropType } from 'vue';
import { uniqueId } from 'lodash';
import { mapGetters } from 'vuex';
import { FormControl } from '@/services/forms/form-control.class';

export default defineComponent({
  name: 'VisualInput',
  emits: ['upload', 'purge'],
  props: {
    control: { type: Object as PropType<FormControl<any>>, default: null },
    uniqueId: { type: String, default: () => uniqueId() }
  },
  computed: {
    ...mapGetters('auth', ['isAnow']),
    fileInputStyle() {
      return this.control.value ? `background-image: url('${this.control.value}');` : '';
    }
  },
  methods: {
    openDialog(event: any) {
      event.target.value = '';
      if (this.control.disabled) event.preventDefault();
    },
    upload(event: Event) {
      const files = (event.target as HTMLInputElement).files;
      this.$emit('upload', { name: this.control.name, file: files?.length && files[0] });
    }
  }
});
