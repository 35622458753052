
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessage from '@/components/Alerts/AlertMessage/AlertMessage.vue';
import CustomBtn from '@/components/Misc/CustomBtn/CustomBtn.vue';
import FileInput from '@/components/Forms/FileInput/FileInput.vue';
import FormCpnt from '@/components/Forms/Form/Form.vue';
import SectionTitle from '@/components/Sections/SectionTitle/SectionTitle.vue';
import SectionHeader from '@/components/Sections/SectionHeader/SectionHeader.vue';
import { FormControl } from '@/services/forms/form-control.class';
import { datesService } from '@/services/dates/dates.service';
import { FormShipping } from '@/services/galleries/galleries.types';

export default defineComponent({
  name: 'ShippingView',
  components: { AlertMessage, CustomBtn, FileInput, FormCpnt, SectionTitle, SectionHeader },
  computed: {
    ...mapState('content', ['content']),
    ...mapState('gallery', ['gallery']),
    ...mapGetters('auth', ['isGallery']),
    newShippingControl() {
      return new FormControl({
        name: 'new-shipping',
        label: 'Upload new shipping form',
        type: 'file',
        value: null
      });
    }
  },
  methods: {
    ...mapActions('content', ['setContent', 'clearContent']),
    ...mapActions('gallery', ['addFormShipping', 'deleteFormShipping']),
    shippingForm(shipping: FormShipping) {
      return new FormControl({
        name: `shipping-${shipping.id}`,
        label: `Shipping form uploaded on ${datesService.format(shipping.created_at)}`,
        type: 'file',
        value: shipping.pdf,
        deletable: this.isGallery,
        class: 'col-4'
      });
    },
    handleDownload() {
      window.open(
        process.env.VUE_APP_PUBLIC_BUCKET_URL +
          '/2024%20EDITION%20-%20FORMS/3_Acces%20Application%20for%20Setting%20Up.pdf',
        '_blank'
      ); //to open in new tab
    },
    handleUpload(event: { name: string; file: File }) {
      if (event.name === 'new-shipping' && !!event.file) this.addFormShipping(event.file);
    }
  },
  mounted() {
    this.setContent('shipping');
  },
  unmounted() {
    this.clearContent();
  }
});
