
import { defineComponent, PropType } from 'vue';

import FileInput from '../FileInput/FileInput.vue';
import TextInput from '../TextInput/TextInput.vue';
import VisualInput from '../VisualInput/VisualInput.vue';
import { FormControl } from '@/services/forms/form-control.class';
import { FormControlOption, FormControlSize, FormControlValue } from '@/services/forms/form-control.types';
import { datesService } from '@/services/dates/dates.service';

export default defineComponent({
  name: 'FormControlCpnt',
  components: {
    FileInput,
    TextInput,
    VisualInput
  },
  emits: ['upload', 'purge'],
  props: {
    control: {
      type: Object as PropType<FormControl<unknown>>,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<FormControlSize>,
      default: 'md'
    }
  },
  data() {
    return {
      controlValue: null,
      controlValueSub: null,
      controlOpts: null,
      controlOptsSub: null,
      controlError: null,
      controlErrorSub: null,
      controlHidden: null,
      controlHiddenSub: null,
      controlLabel: null,
      controlLabelSub: null,
      controlRequired: null,
      controlRequiredSub: null
    };
  },
  computed: {
    controlSize() {
      return this.control.size || this.size;
    },
    controlDisplayValue() {
      if (this.control.type === 'dropdown') {
        const option = this.controlOpts.find(opt => opt.value === this.controlValue);
        return option?.label;
      } else if (this.control.type === 'datetime-local') {
        return this.controlValue ? datesService.format(this.controlValue) : '-';
      } else if (this.control.type === 'multi-dropdown') {
        return this.controlValue?.map(value => this.controlOpts.find(opt => opt.value === value)?.label).join(', ');
      } else return `${this.controlValue || '-'} ${this.control.unit || ''}`;
    }
  },
  watch: {
    control: {
      immediate: true,
      handler() {
        this.controlValueSub = this.control.value$.subscribe((value: FormControlValue) => (this.controlValue = value));
        this.controlOptsSub = this.control.options$.subscribe((opts: FormControlOption[]) => {
          this.controlOpts = opts.sort((a, b) => (a.indexWeight || 0) - (b.indexWeight || 0));
        });
        this.controlHiddenSub = this.control.hidden$.subscribe((hidden: boolean) => (this.controlHidden = hidden));
        this.controlLabelSub = this.control.label$.subscribe((label: string) => (this.controlLabel = label));
        this.controlRequiredSub = this.control.required$.subscribe((req: boolean) => (this.controlRequired = req));
        this.controlErrorSub = this.control.errors$.subscribe((errors: string[]) => {
          this.controlError = errors[0];
          /* We focus the input if there is an error */
          if (this.controlError) {
            console.log(this.controlError);
            const input = this.$refs.input as HTMLInputElement;
            if (input) input.focus();
          }
        });
      }
    }
  },
  unmounted() {
    this.controlValueSub.unsubscribe();
    this.controlOptsSub.unsubscribe();
    this.controlErrorSub.unsubscribe();
    this.controlHiddenSub.unsubscribe();
    this.controlLabelSub.unsubscribe();
    this.controlRequiredSub.unsubscribe();
  }
});
