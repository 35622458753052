
import { defineComponent, PropType } from 'vue';
import { range } from 'lodash';

import { PaginationOptions } from '@/core/axios.types';

export default defineComponent({
  name: 'PagingControls',
  props: {
    pagination: {
      type: Object as PropType<PaginationOptions>,
      default: null
    },
    count: {
      type: Number,
      default: null
    }
  },
  computed: {
    pageCount() {
      return Math.ceil((this.count || 0) / this.pagination?.limit);
    },
    pageIndices() {
      const page = this.pagination?.page;
      if (this.pageCount < 7) return range(1, this.pageCount + 1);
      else if (page < 3 || page > this.pageCount - 2) {
        return [...range(1, 4), '...', ...range(this.pageCount - 2, this.pageCount + 1)];
      } else return [1, '...', page - 1, page, page + 1, '...', this.pageCount];
    },
    canLoadPrevious() {
      return this.pagination?.page > 1;
    },
    canLoadNext() {
      return this.pagination?.page < this.pageCount;
    }
  },
  methods: {
    loadPrevious() {
      if (this.canLoadPrevious) this.$emit('page', this.pagination?.page - 1);
    },
    loadNext() {
      if (this.canLoadNext) this.$emit('page', this.pagination?.page + 1);
    }
  }
});
