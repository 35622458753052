import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd1a327c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "fa-solid fa-exclamation-circle me-2 text-danger"
}
const _hoisted_3 = {
  key: 1,
  class: "inline-control-value"
}
const _hoisted_4 = {
  key: 5,
  class: "feedback-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_visual_input = _resolveComponent("visual-input")!
  const _component_file_input = _resolveComponent("file-input")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_ctx.control && !_ctx.controlHidden)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["form-control-container", [{ invalid: !_ctx.control.valid }, { disabled: _ctx.control.disabled || _ctx.disabled }, _ctx.inline, _ctx.control.class]]),
        style: _normalizeStyle(_ctx.control.style)
      }, [
        (_ctx.control.type !== 'file' && _ctx.controlLabel)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              class: "form-label",
              for: _ctx.control.name
            }, [
              (!_ctx.control.valid)
                ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.controlLabel), 1)
            ], 8, _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.inline && _ctx.disabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.controlDisplayValue), 1))
          : _createCommentVNode("", true),
        (_ctx.control.type === 'visual')
          ? (_openBlock(), _createBlock(_component_visual_input, {
              key: 2,
              control: _ctx.control,
              onUpload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('upload', $event))),
              onPurge: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('purge', $event)))
            }, null, 8, ["control"]))
          : (_ctx.control.type === 'file')
            ? (_openBlock(), _createBlock(_component_file_input, {
                key: 3,
                control: _ctx.control,
                onUpload: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('upload', $event))),
                onPurge: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('purge', $event)))
              }, null, 8, ["control"]))
            : (_openBlock(), _createBlock(_component_text_input, {
                key: 4,
                uniqueId: _ctx.control.name,
                value: _ctx.controlValue,
                type: _ctx.control.type,
                unit: _ctx.control.unit,
                disabled: _ctx.control.disabled || _ctx.disabled,
                required: _ctx.controlRequired,
                invalid: !!_ctx.controlError,
                size: _ctx.controlSize,
                step: _ctx.control.step,
                min: _ctx.control.min,
                minlength: _ctx.control.minlength,
                max: _ctx.control.max,
                maxlength: _ctx.control.maxlength,
                onEdit: _cache[4] || (_cache[4] = ($event: any) => (_ctx.control.value = $event))
              }, null, 8, ["uniqueId", "value", "type", "unit", "disabled", "required", "invalid", "size", "step", "min", "minlength", "max", "maxlength"])),
        (_ctx.controlError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.controlError), 1))
          : _createCommentVNode("", true)
      ], 6))
    : _createCommentVNode("", true)
}