
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import ModalLayout from '@/components/Modals/ModalLayout/ModalLayout.vue';
import ModalSection from '@/components/Modals/ModalSection/ModalSection.vue';
import FormCpnt from '@/components/Forms/Form/Form.vue';
import { EditWorkModalData } from './EditWorkModal.types';

export default defineComponent({
  name: 'EditWorkModal',
  components: { ModalLayout, ModalSection, FormCpnt },
  data() {
    return {
      init: null as EditWorkModalData
    };
  },
  computed: {
    ...mapState('modal', ['modal']),
    ...mapState('work', ['work']),
    ...mapGetters('work', ['workForm'])
  },
  methods: {
    ...mapActions('work', ['updateWork', 'updateWorkFiles', 'deleteWork', 'deleteWorkPicture']),
    handleUpload(event: { name: string; file: File }) {
      if (event.name === 'picture' && !!event.file) {
        this.updateWorkFiles({ files: { [event.name]: event.file }, work: this.workForm.value });
      }
    },
    save() {
      if (this.workForm.validate()) {
        this.updateWork({ ...this.work, ...this.workForm.value }).then(() => this.$emit('close', true));
      }
    },
    cancel() {
      if (this.init.editOrCreate === 'create')
        this.deleteWork({ workId: this.work.id, showAlert: false }).then(() => this.$emit('close', false));
      else this.$emit('close', false);
    }
  },
  mounted() {
    this.init = this.modal.data;
  }
});
