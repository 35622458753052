
import { defineComponent, PropType } from 'vue';

import { ModalSectionDirection, ModalSlideDirection } from './ModalSection.types';

export default defineComponent({
  name: 'ModalSection',
  props: {
    slide: {
      type: String as PropType<ModalSlideDirection>,
      default: null
    },
    direction: {
      type: String as PropType<ModalSectionDirection>,
      default: 'row'
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    noBg: {
      type: Boolean,
      default: false
    },
    noScroll: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    slideClass() {
      return this.slide ? `slide-${this.slide}` : '';
    },
    directionClass() {
      return this.direction ? `direction-${this.direction}` : '';
    }
  }
});
