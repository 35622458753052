
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import CustomSidebarItem from '@/components/Sidebar/CustomSidebarItem/CustomSidebarItem.vue';
import UserSettingsBox from '@/components/Sidebar/UserSettingsBox/UserSettingsBox.vue';
import { navigationService } from '@/services/navigation/navigation.service';

export default defineComponent({
  name: 'CustomSidebar',
  components: { CustomSidebarItem, UserSettingsBox },
  props: {
    route: {
      type: String,
      default: null
    },
    showLogo: {
      type: Boolean,
      default: true
    },
    showGallery: {
      type: Boolean,
      default: false
    },
    showUser: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      items: null
    };
  },
  computed: {
    ...mapState('gallery', ['gallery']),
    ...mapGetters('auth', ['allowedSections']),
    logoUrl() {
      return process.env.VUE_APP_PUBLIC_BUCKET_URL + '/logo_transparent.png';
    }
  },
  mounted() {
    this.items = navigationService.filterItems(
      this.$route.matched[this.$route.matched.length - 1].children,
      this.allowedSections
    );
    if (this.items?.length > 0) this.$router.push({ name: this.items[0].route });
  },
  methods: {
    disableSection(item) {
      return item.route === 'gallery-broadcast';
    },
    openPage(item) {
      if (!this.disableSection(item)) {
        this.$router.push({ name: item.route });
      }
    }
  }
});
